import { css } from "@emotion/css"
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  share,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from "rxjs"
import { classSync, withAnimationFrame } from "@taterer/rx-jsx"
import { Tool } from "../../../domain/toolSettings"
import { setActiveToolPanel } from "../../../domain/toolSettings/command"
import { activeToolPanel$ } from "../../../domain/toolSettings/event"
import { removeOverlayDetail } from "../../../domain/displayOverlayDetail/command"
import Datagrid from "../Datagrid"
import { withIndexedDb } from "../../../persistence/indexedDB"
import { flowEntity$ } from "../../../domain/flow/event"
import { datagridEntity$ } from "../../../domain/datagrid/event"
import { makeItFlow } from "../../../domain/flow"
import { newDatagrid } from "../../../domain/datagrid/command"
import { getAllFlowsArray } from "../../../domain/flow/query"
import { removeView } from "../../../domain/view/command"
import { overlayToolsClass, toolItem } from "../../styles"

const detailClass = css`
  background-color: #add8e6ee;
  width: max-content;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
`

const activeDetailClass = css`
  background-color: #eafaff !important;
`

export default function ViewDetail ({ destruction$, id }: { destruction$: Observable<any>, id: string }) {
  const view$ = of(
    <div
      class={activeDetailClass}
      style='background-color: white;'
      onmousedown={event => {
        setActiveToolPanel({ id, type: Tool.view, collapse: () => removeOverlayDetail({ id }) })
        event.stopPropagation()
      }}>
      <Datagrid destruction$={destruction$} id={id} />
    </div>
  ).pipe(share())

  activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    withLatestFrom(view$),
    distinctUntilChanged(),
    takeUntil(destruction$)
  )
  .subscribe(([isActive, view]) => {
    classSync(view, activeDetailClass, isActive)
  })

  const tool$ = activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    startWith(true),
    distinctUntilChanged(),
    map(active => {
      if (!active) return <div />
      return (
        <div class={overlayToolsClass}>
          <div class={toolItem} onclick={() => {
            removeView({ id })
            setActiveToolPanel(undefined)
          }}>
            <i class="material-icons dp48">delete</i>
          </div>
        </div>
      )
    }),
    takeUntil(destruction$)
  )

  // get all flows, then makeItFlow, and upsert the datagrid with the results
  merge(flowEntity$, datagridEntity$.pipe(filter(i => i.id !== id)))
  .pipe(
    startWith(1),
    debounceTime(10),
    withAnimationFrame,
    switchMap(() => from(getAllFlowsArray())),
    withIndexedDb(),
    switchMap(([flows, db]) => makeItFlow(db, flows, id)),
    map(value => {
      if (typeof value === 'string') {
        newDatagrid({ id })
      } else {
        newDatagrid(value)
      }
    }),
    takeUntil(destruction$)
  )
  .subscribe()

  return (
    <div class={detailClass}
      onmousedown={() => {
        setActiveToolPanel({ id, type: Tool.view, collapse: () => removeOverlayDetail({ id }) })
      }}>
      <div class={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}>
        <div>
          <div single$={tool$}></div>
          <span>Visualization</span>
        </div>
        <div style='cursor: pointer;' onclick={() => {
          setActiveToolPanel(undefined)
          removeOverlayDetail({ id })
        }}><i class="material-icons dp48">close</i></div>
      </div>
      <div single$={view$} />
    </div>
  )
}
