import {
  takeUntil,
  Observable,
  map,
  timer,
  of
} from "rxjs"
import { Calculation } from "../../../domain/calculation/event"
import { getCalculation } from "../../../domain/calculation/query"
import { newFlow } from "../../../domain/flow/command"
import { getVariables } from "../../../domain/mathite"
import { stripShapeFromId } from "../../../domain/shape"
import { Shape } from "../../../domain/shape/event"
import { IndexedDBEntity } from "../../../persistence/indexedDB"
import { inputClass } from "../../styles"
import { parseTransferData } from "./io"

type Input = {
  index: number
  title: string
}

function getCalculationInputs (calculation: Calculation): Input[] {
  return getVariables(calculation.equation).map((variable, index) => ({ index, title: variable }))
}

export default function ({ destruction$, shape }: { destruction$: Observable<any>, shape: Shape }) {
  const id = stripShapeFromId(shape.id)

  const inputs$ = getCalculation(id)
  .pipe(
    map(getCalculationInputs),
    map(inputs => {
      return (
        <div>
          {inputs.map(input => {
            const variable$ = of(
              <span
                id={`input-${input.index}-${id}`}
                class={inputClass}
                // TODO make it draggable in the other direction
                // ondragstart={event => setTransferData(event, { source: Source.code, id: fabricMathite.mathite.id, variable })}
                draggable="true"
                ondragover={allowDrop}
                ondrop={event => {
                  newFlow({
                    ...parseTransferData(event),
                    destinationEntity: IndexedDBEntity.calculation,
                    destinationId: id,
                    destinationIndex: input.index,
                    destinationTitle: input.title,
                  })
                }}
              >{input.title}</span>
            )

            function allowDrop (event) {
              event.preventDefault()
            }

            return (
              <div style={`padding: 12px 0px;`} single$={variable$} />
          )})}
        </div>
      )
    }),
    takeUntil(destruction$)
  )

  return (
    <div single$={inputs$} />
  )
}
