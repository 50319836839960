import { css } from "@emotion/css"

export default function Footer () {
  return (
    <div class={css`
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      bottom: 0px;
      left: 0px;
      align-items: center;
      flex-direction: column;
      width: 100%;
    `}>
      <div class={css`
        width: 100%;
        max-height: 240px;
        background-color: #2196f3;
        margin-top: 40px;
        padding: 40px;
        display: flex;
        justify-content: center;
      `}>
        <div class={css`color: lightgray;`}>
          Copyright © 2022<a class={css`
            padding: 10px;
            color: white;
          `} href='https://existential.company' target='_blank'>Existential LLC</a>
        </div>
      </div>
    </div>
  )
}