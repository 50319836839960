import { merge } from "rxjs"
import { eventFactory } from "@taterer/rx-entity"
import { IndexedDBEntity } from "../../persistence/indexedDB"

export enum CalculationEvent {
  new = 'new',
  remove = 'remove',
  rename = 'rename',
  move = 'move',
  formulate = 'formulate',
}

export interface NewCalculation {
  id?: string,
  title?: string,
  equation?: string
  notes?: string
  output?: string
  x: number,
  y: number,
}

export const [newCalculation$, newCalculation] = eventFactory<NewCalculation>({ entity: IndexedDBEntity.calculation, eventType: CalculationEvent.new })

export interface RemoveCalculation {
  id: string
}

export const [removeCalculation$, removeCalculation] = eventFactory<RemoveCalculation>({ entity: IndexedDBEntity.calculation, eventType: CalculationEvent.remove })

export interface RenameCalculation {
  id: string
  title: string
}

export const [renameCalculation$, renameCalculation] = eventFactory<RenameCalculation>({ entity: IndexedDBEntity.calculation, eventType: CalculationEvent.rename })

export interface MoveCalculation {
  id: string
  x: number
  y: number
}

export const [moveCalculation$, moveCalculation] = eventFactory<MoveCalculation>({ entity: IndexedDBEntity.calculation, eventType: CalculationEvent.move })

export interface FormulateCalculation {
  id: string
  equation: string
  output: string
  notes?: string
}

export const [formulateCalculation$, formulateCalculation] = eventFactory<FormulateCalculation>({ entity: IndexedDBEntity.calculation, eventType: CalculationEvent.formulate })

export const calculationCommands$ = merge(
  newCalculation$,
  removeCalculation$,
  renameCalculation$,
  moveCalculation$,
  formulateCalculation$,
)
