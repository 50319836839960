import * as d3 from 'd3'
import { fromEvent, switchMap, takeUntil, withLatestFrom, of, share, take } from 'rxjs'
import { css } from '@emotion/css'
import { withAnimationFrame } from '@taterer/rx-jsx'
import Modal from '../../components/Modal'
import ControlPanel from '../../components/ControlPanel'
import { drawShape, mountSVG, mountSVG$, newShapeAggregate, toolToShapeMap } from '../../../domain/shape'
import { activeTool$ } from '../../../domain/toolSettings/event'
import { Tool } from '../../../domain/toolSettings'
import { setActiveTool, setActiveToolPanel } from '../../../domain/toolSettings/command'
import { subscribeToAllLoadersAndAddShapes } from './loader'
// import ToolPanel from '../../components/ToolPanel'
import Tutorial from '../../components/Tutorial'
import { overlay$, transformRegex } from './overlay'
import { overlayDetail$ } from './overlayDetail'
import { subscribeToLines } from './lines'
import { continueTutorial } from '../../../domain/tutorial/command'

let mouseClickX = 0
let mouseClickY = 0

const hoverId = 'ghost-shape'

export default function Editor ({ destruction$ }) {
  const mount$ = of(
    <div class={css`
      height: 100%;
      width: 100%;
      top: 0px;
      left: 0px;
      padding: 0px;
      position: absolute;
      overflow: hidden;
    `} />
  ).pipe(
    takeUntil(destruction$),
    share()
  )

  // Original svg mount
  mount$
  .pipe(
    withAnimationFrame,
    takeUntil(destruction$)
  )
  .subscribe(mount => mountSVG(d3.select(mount)
    .append('svg')
    .style("height", '100%')
    .style("width", '100%')
  ))

  // Dropping in new shapes
  mount$
  .pipe(
    switchMap(mount => fromEvent(mount, 'mouseup')),
    withLatestFrom(activeTool$),
    takeUntil(destruction$)
  )
  .subscribe(([event, activeTool]) => {
    if (activeTool !== Tool.pointer) {
      newShapeAggregate({ x: (event as any).x, y: (event as any).y }, activeTool)
      setActiveTool(Tool.pointer)
    }
  })

  // Escape key cancels drop behavior and reselects the pointer tool
  fromEvent(document, 'keydown')
  .pipe(
    takeUntil(destruction$)
  )
  .subscribe(event => {
    if ((event as any).key === 'Escape') {
      setActiveTool(Tool.pointer)
    }
  })

  // When the active tool changes, drop-in a ghost shape for placement
  activeTool$
  .pipe(
    withLatestFrom(mountSVG$),
    takeUntil(destruction$)
  )
  .subscribe(([activeTool, svg]) => {
    d3.select(`#${hoverId}`).remove()
    if (activeTool !== Tool.pointer) {
      drawShape(svg, { id: hoverId, x: mouseClickX, y: mouseClickY, type: toolToShapeMap[activeTool] }).attr('opacity', .5)
    }
  })

  mountSVG$
  .pipe(
    withAnimationFrame,
    take(1),
    takeUntil(destruction$)
  ).subscribe(() => {
    // Load everything
    subscribeToAllLoadersAndAddShapes(destruction$)
  })

  subscribeToLines(destruction$)
  
  const root$ = of(
    <div
      onmousedown={() => setActiveToolPanel(undefined)}
      onmouseover={event => {
        mouseClickX = event.x
        mouseClickY = event.y
      }}>
      <div single$={mount$} />
      <div class={css`
        z-index: 0;
        pointer-events: none;
        padding: 0px;
        top: 0px;
        left: 0px;
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
      `}>
        <div multi$={overlay$(destruction$)}
          class={css`
            position: relative;
          `} />
      </div>
      <div class={css`
        pointer-events: none;
        padding: 0px;
        top: 0px;
        left: 0px;
        position: absolute;
        overflow: hidden;
        height: 100%;
        width: 100%;
      `}>
        <div multi$={overlayDetail$(destruction$)}
          class={css`
            position: relative;
          `} />
      </div>
      <Tutorial destruction$={destruction$} />
      <ControlPanel destruction$={destruction$} />
      <Modal destruction$={destruction$} />
      {/* <ToolPanel destruction$={destruction$} /> */}
      <div
        onclick={() => continueTutorial({ id: 'userid' })}
        class={css`
          position: absolute;
          bottom: 32px;
          right: 32px;
          font-size: 2em;
          color: #039be5;
          cursor: pointer;
        `}>?</div>
    </div>
  ).pipe(
    takeUntil(destruction$),
    share()
  )

  // Drop-in; move the ghost shape from the root (to capture tool hovers)
  root$
  .pipe(
    switchMap(root => fromEvent(root, 'mousemove')),
    withLatestFrom(activeTool$),
    takeUntil(destruction$)
  )
  .subscribe(([event, activeTool]) => {
    if (activeTool !== Tool.pointer) {
      const element = d3.select(`#${hoverId}`)
      element.attr('transform', element.attr('transform').replace(transformRegex, `translate(${(event as any).x}, ${(event as any).y})`))
    }
  })

  return <div single$={root$} />
}
