import {
  Observable,
  BehaviorSubject,
  takeUntil,
  withLatestFrom,
  Subject,
  of,
  map,
  share
} from 'rxjs'
import { css } from '@emotion/css'
import { ColumnStyle } from '../../../domain/datagrid'
import { classSync, withAnimationFrame } from '@taterer/rx-jsx'
import { renameColumn, setCell } from '../../../domain/datagrid/command'

const hiddenClass = css`
  position: absolute;
  display: none;
`

const datagridClass = css`
  flex-grow: 1;
  
  /* label color */
  /* label {
    color: #000 !important;
  } */
  /* label focus color */
  /* input[type=text]:focus + label {
    color: #000 !important;
  } */
  /* label underline focus color */
  input[type=text]:focus {
    border-bottom: 1px solid #57d5ff !important;
    box-shadow: 0 1px 0 0 #57d5ff !important;
  }
  /* valid color */
  input[type=text].valid {
    border-bottom: 1px solid #000 !important;
    box-shadow: 0 1px 0 0 #000 !important;
  }
  /* invalid color */
  input[type=text].invalid {
    border-bottom: 1px solid red !important;
    box-shadow: 0 1px 0 0 red !important;
  }
  /* icon prefix focus color */
  .prefix.active {
    color: #57d5ff !important;
  }
`

export default function Cell ({ destruction$, datagridId, datagridRowByIndex$, rowIndex, columnIndex, cell, columnStyle }: {
  destruction$: Observable<any>,
  datagridRowByIndex$: Observable<string[]>,
  datagridId: string,
  rowIndex: number,
  columnIndex: number,
  cell: string,
  columnStyle?: ColumnStyle
}) {
  if (columnStyle?.hidden) {
    return <div />
  }
  const valueStream$ = new BehaviorSubject(cell)
  const editStream$ = new Subject<{ toString: Function }>()
  const selection$ = new BehaviorSubject<{ startOffset: number, endOffset: number } | undefined>(undefined)

  const value$ = valueStream$
  .pipe(
    map(value => <div>{value}</div>),
    takeUntil(destruction$)
  )

  const edit$ = selection$
  .pipe(
    map(selection => {
      if (selection) {
        return <input style='font-family: Open Sans, serif;' type="text" value={valueStream$.value}
          onkeyup={event => editStream$.next(event.target.value)} // immediate updates
          onblur={event => {
            editStream$.next(event.target.value)
            selection$.next(undefined)
          }
        } />
      }
    }),
    share(),
    takeUntil(destruction$)
  )

  const content$ = of(
    <div
      class={css`
        caret-color: transparent;
        white-space: nowrap;
        padding: 10px 6px;
        height: 44px;
      `}
      onClick={() => {
        const selection = window.getSelection()?.getRangeAt(0)
        if (selection) {
          selection$.next(selection)
        } else {
          selection$.next({ startOffset: 0, endOffset: 0 })
        }
      }}>
      <div single$={value$} />
    </div>
  )

  datagridRowByIndex$
  .pipe(
    withLatestFrom(valueStream$),
    takeUntil(destruction$)
  )
  .subscribe(([row, value]) => {
    if (row && row[columnIndex] !== value) {
      valueStream$.next(row[columnIndex])
    }
  })

  selection$
  .pipe(
    withLatestFrom(content$),
    takeUntil(destruction$)
  )
  .subscribe(([selection, content]) => {
    classSync(content, hiddenClass, !!selection)
  })

  selection$
  .pipe(
    withAnimationFrame,
    withLatestFrom(edit$),
    takeUntil(destruction$)
  )
  .subscribe(([selection, element]: any) => {
    if (selection) {
      element.focus()
      element.setSelectionRange(selection.startOffset, selection.endOffset)
    }
  })

  editStream$
  .pipe(
    takeUntil(destruction$),
  )
  .subscribe(value => {
    if (rowIndex === -1) {
      renameColumn({ id: datagridId, index: columnIndex, title: value.toString() })
    } else {
      setCell({ id: datagridId, index: columnIndex, rowIndex, value: value.toString() })
    }
  })

  return (
    <div class={datagridClass} style={`overflow: hidden; position: relative; min-width: ${columnStyle?.width ? columnStyle.width : '200'}px;`}>
      <div class={css`
          position: absolute;
          top: 0px;
          left: 0px;
          z-index: 10;
          padding: 0px 6px;
        `} single$={edit$}>
        </div>
      <div class={css`
        height: 3em;
        border: lightgray 2px solid;
      `} single$={content$}>
      </div>
    </div>
  )
}
