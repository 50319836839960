import { delay, merge, Observable, share, take, takeUntil } from "rxjs"
import { subscribeToAggregationServices } from "../../../domain/aggregation/event"
import { subscribeToCalculationServices } from "../../../domain/calculation/event"
import { getAllCalculations } from "../../../domain/calculation/query"
import { getAllAggregations } from "../../../domain/aggregation/query"
import { subscribeToDatabaseServices } from "../../../domain/database/event"
import { getAllDatabases } from "../../../domain/database/query"
import { generateShapeId, toolToShapeMap } from "../../../domain/shape"
import { newShape, ShapeType } from "../../../domain/shape/command"
import { subscribeToSplitServices } from "../../../domain/split/event"
import { getAllSplits } from "../../../domain/split/query"
import { subscribeToViewServices } from "../../../domain/view/event"
import { getAllViews } from "../../../domain/view/query"
import { subscribeToFlowServices } from "../../../domain/flow/event"
import { getAllFlows } from "../../../domain/flow/query"
import { newLine } from "../../../domain/line/command"
import { withAnimationFrame } from "@taterer/rx-jsx"

const allDatabases$ = getAllDatabases().pipe(share())
const allCalculations$ = getAllCalculations().pipe(share())
const allSplits$ = getAllSplits().pipe(share())
const allAggregations$ = getAllAggregations().pipe(share())
const allViews$ = getAllViews().pipe(share())

export function subscribeToAllLoadersAndAddShapes (destruction$: Observable<any>) {
  // setup subscription for all resources, upon completion: load flows
  loadFlows(destruction$)

  // Load databases
  allDatabases$
  .pipe(
    takeUntil(destruction$)
  )
  .subscribe(entity => {
    newShape({ id: generateShapeId(entity.id, ShapeType.square), type: ShapeType.square, x: entity.x, y: entity.y })
  })

  subscribeToDatabaseServices(destruction$)

  // Load calculations
  allCalculations$
  .pipe(
    takeUntil(destruction$)
  )
  .subscribe(entity => {
    newShape({ id: generateShapeId(entity.id, ShapeType.hexPara), type: ShapeType.hexPara, x: entity.x, y: entity.y })
  })

  subscribeToCalculationServices(destruction$)

  // Load split
  allSplits$
  .pipe(
    takeUntil(destruction$)
  )
  .subscribe(entity => {
    newShape({ id: generateShapeId(entity.id, ShapeType.diamond), type: ShapeType.diamond, x: entity.x, y: entity.y })
  })

  subscribeToSplitServices(destruction$)

  // Load aggregations
  allAggregations$
  .pipe(
    takeUntil(destruction$)
  )
  .subscribe(entity => {
    newShape({ id: generateShapeId(entity.id, ShapeType.eastTriangle), type: ShapeType.eastTriangle, x: entity.x, y: entity.y })
  })

  subscribeToAggregationServices(destruction$)

  // Load views
  allViews$
  .pipe(
    takeUntil(destruction$)
  )
  .subscribe(entity => {
    newShape({ id: generateShapeId(entity.id, ShapeType.circle), type: ShapeType.circle, x: entity.x, y: entity.y })
  })

  subscribeToViewServices(destruction$)

  subscribeToFlowServices(destruction$)
}

function loadFlows (destruction$) {
  return merge(
    allDatabases$,
    allCalculations$,
    allSplits$,
    allAggregations$,
    allViews$,
  )
  .pipe(
    delay(100),
    withAnimationFrame,
    take(1),
    takeUntil(destruction$)
  ).subscribe({
    complete: () => {
      // Load flows
      getAllFlows()
      .pipe(
        takeUntil(destruction$)
      )
      .subscribe(entity => {
        newLine({
          id: `${entity.sourceIndex}-${entity.sourceId}-${entity.destinationIndex}-${entity.destinationId}`,
          destinationId: entity.destinationId,
          destinationElementId: `input-${entity.destinationIndex}-${entity.destinationId}`,
          destinationShapeId: generateShapeId(entity.destinationId, toolToShapeMap[entity.destinationEntity]),
          sourceId: entity.sourceId,
          sourceElementId: `output-${entity.sourceIndex}-${entity.sourceId}`,
          sourceShapeId: generateShapeId(entity.sourceId, toolToShapeMap[entity.sourceEntity]),
          color: '#2196f3',
        })
      })
    }
  })
}