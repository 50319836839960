export function getColor (text: string): string {
  let total = 0
  for (let i = 0; i < text.length; i++) {
    total += text.charCodeAt(i) * 16
  }
  if (total < 1000) {
    total = 1000 - total
  }
  const color = {
    r: total % 64 * 4,
    g: 0,
    b: 0
  }
  color.g = (total) % 128 * 2
  color.b = (total) % 256

  if (color.r > 96 && color.g > 96 && color.b > 96) {
    // If the colors are all high it will be too light
    switch (Math.max(color.r, color.g, color.b)) {
    case color.r:
      color.g -= 96
      color.b -= 96
      break
    case color.g:
      color.r -= 96
      color.b -= 96
      break
    case color.b:
      color.r -= 96
      color.g -= 96
      break
    default:
      break
    }
  } else if (color.r < 128 && color.g < 128 && color.b < 128) {
    // If the colors are all too low it will be too dark
    switch (Math.max(color.r, color.g, color.b)) {
    case color.r:
      color.r += 128
      break
    case color.g:
      color.g += 128
      break
    case color.b:
      color.b += 128
      break
    default:
      break
    }
  } else if ((color.r % color.g + color.g % color.b + color.b % color.r) < 256) {
    // If the colors are all too close together it will be gray
    switch (Math.max(color.r, color.g, color.b)) {
    case color.r:
      color.r = color.r % 128 + 128
      break
    case color.g:
      color.g = color.g % 128 + 128
      break
    case color.b:
      color.b = color.b % 128 + 128
      break
    default:
      break
    }
  }
  return rgbToHex(color.r, color.g, color.b)
}

function rgbToHex (r, g, b): string {
  return '#' + intToHex(r) + intToHex(g) + intToHex(b)
}

function intToHex (c: any): string {
  const hex = c.toString(16)
  return hex.length === 1 ? '0' + hex : hex
}
