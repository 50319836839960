import { from, shareReplay, withLatestFrom } from "rxjs"
import { Persistable, Persistence } from '@taterer/persist'
import { memoryFactory } from "@taterer/persist-memory"

export enum MemoryEntity {
  line = 'line',
  shape = 'shape',
  displayOverlayDetail = 'displayOverlayDetail',
}

export async function memoryPersistence (): Promise<Persistence<any & Persistable, MemoryEntity>> {
  const memoryDB = await memoryFactory([
    {
      name: MemoryEntity.line,
    },
    {
      name: MemoryEntity.shape,
    },
    {
      name: MemoryEntity.displayOverlayDetail,
    },
  ])
  return memoryDB
}

export const memoryDB$ = from(memoryPersistence()).pipe(shareReplay(1))
// sudo subject
memoryDB$.subscribe()

export function withMemoryDB<T> () {
  return withLatestFrom<T, [Persistence<any & Persistable, MemoryEntity>]>(memoryDB$)
}
