import { shareReplay } from "rxjs";
import {
  EntityEventHandlers,
  entityServiceFactory,
} from "@taterer/rx-entity";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import {
  CompleteTutorialStep,
  ContinueTutorial,
  NewTutorial,
  RemoveTutorial,
  RestartTutorial,
  SkipTutorial,
  tutorialCommands$,
  TutorialEvent
} from './command';

export interface Tutorial {
  id: string
  deleted?: boolean
  created_at: number
  stepIndex: number
  active: boolean
}

const tutorialEventHandlers: EntityEventHandlers<Tutorial, TutorialEvent> = {
  [TutorialEvent.new]: (entity, _event: NewTutorial) => {
    return {
      id: 'userid',
      created_at: Date.now(),
      stepIndex: 0,
      active: true,
      ...entity,
    }
  },
  [TutorialEvent.remove]: (entity, _event: RemoveTutorial) => {
    return {
      ...entity,
      deleted: true
    }
  },
  [TutorialEvent.skip]: (entity, _event: SkipTutorial) => {
    return {
      ...entity,
      active: false,
    }
  },
  [TutorialEvent.continue]: (entity, _event: ContinueTutorial) => {
    return {
      ...entity,
      active: true,
    }
  },
  [TutorialEvent.restart]: (entity, _event: RestartTutorial) => {
    return {
      ...entity,
      stepIndex: 0,
      active: true,
    }
  },
  [TutorialEvent.completeStep]: (entity, _event: CompleteTutorialStep) => {
    return {
      ...entity,
      stepIndex: entity.stepIndex + 1
    }
  },
}

export const tutorialEntity$ = tutorialCommands$
  .pipe(
    entityServiceFactory<Tutorial, any>(
      indexedDB$,
      IndexedDBEntity.tutorial,
      tutorialEventHandlers,
    ),
    shareReplay(1)
  )
