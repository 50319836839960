import { map, Observable, takeUntil } from "rxjs";
import { Split } from "../../../domain/split/event";
import { getSplit } from "../../../domain/split/query";
import { stripShapeFromId } from "../../../domain/shape";
import { Shape } from "../../../domain/shape/event";
import { FlowSource } from "../../../domain/flow/command";
import { IndexedDBEntity } from "../../../persistence/indexedDB";
import { outputClass } from "../../styles";
import OutputArrow from '../OutputArrow'

type Output = {
  index: number,
  title: string,
}

function getSplitOutputs (split: Split): Output[] {
  const outputTitles: string[] = split.branches.map(branch => branch.comparison)
  outputTitles.push('Else')
  return outputTitles.map((output, index) => ({ title: output, index }))
}

function setTransferData (event, data: FlowSource) {
  event.dataTransfer.setData('text', JSON.stringify(data))
}

export default function SplitOutput ({ destruction$, shape }: { destruction$: Observable<any>, shape: Shape }) {
  const id = stripShapeFromId(shape.id)

  const outputs$ = getSplit(id)
  .pipe(
    map(getSplitOutputs),
    map(outputs => {
      return (
        <div>
          {outputs.map(output => <div>
            <div style={`padding: 12px 0px;`}>
              <span
                id={`output-${output.index}-${id}`}
                class={outputClass}
                draggable="true"
                ondragstart={event => setTransferData(event, {
                  sourceEntity: IndexedDBEntity.split,
                  sourceId: id,
                  sourceIndex: output.index,
                  sourceTitle: output.title,
                })}
                >
                  <OutputArrow />
                  {output.title}
                </span>
            </div>
          </div>)}
        </div>
      )
    }),
    takeUntil(destruction$)
  )

  return (
    <div single$={outputs$} />
  )
}
