import { v4 as uuid } from 'uuid'
import { Observable, shareReplay } from "rxjs";
import { newDatagrid, removeDatagrid } from "../datagrid/command";
import {
  SideEventHandlers,
  EntityEventHandlers,
  entityServiceFactory,
  subscriptionFactory
} from "@taterer/rx-entity";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { newShape, removeShape } from '../shape/command';
import { datagridEntity$ } from '../datagrid/event';
import {
  NewDatabase,
  databaseCommands$,
  DatabaseEvent,
  MoveDatabase,
  RemoveDatabase,
  RenameDatabase
} from './command';
import { generateShapeId, toolToShapeMap } from '../shape';

export interface Database {
  id: string
  deleted?: boolean
  created_at: number
  x: number
  y: number
  title?: string
}

const databaseEventHandlers: EntityEventHandlers<Database, DatabaseEvent> = {
  [DatabaseEvent.new]: (_, event: NewDatabase) => {
    return {
      id: event.id || uuid(),
      created_at: Date.now(),
      ...event,
    }
  },
  [DatabaseEvent.remove]: (entity, event: RemoveDatabase) => {
    return {
      ...entity,
      deleted: true
    }
  },
  [DatabaseEvent.rename]: (entity, event: RenameDatabase) => {
    return {
      ...entity,
      title: event.title
    }
  },
  [DatabaseEvent.move]: (entity, event: MoveDatabase) => {
    return {
      ...entity,
      x: event.x || entity.x,
      y: event.y || entity.y,
    }
  },
}

const databaseSideEventHandlers: SideEventHandlers<Database, DatabaseEvent> = {
  [DatabaseEvent.new]: (_, event: NewDatabase, updatedEntity): Database => {
    newDatagrid({ id: updatedEntity.id })
    newShape({
      id: generateShapeId(updatedEntity.id, toolToShapeMap.database),
      x: event.x,
      y: event.y,
      type: toolToShapeMap.database,
    })
    return updatedEntity
  },
  [DatabaseEvent.remove]: (_, event, updatedEntity) => {
    removeDatagrid({ id: event.id })
    removeShape({ id: generateShapeId(event.id, toolToShapeMap.database) })
    return updatedEntity
  },
}

export const databaseEntity$ = databaseCommands$
  .pipe(
    entityServiceFactory<Database, any>(
      indexedDB$,
      IndexedDBEntity.database,
      databaseEventHandlers,
      databaseSideEventHandlers
    ),
    shareReplay(1)
  )

export function subscribeToDatabaseServices (destruction$: Observable<any>) {
  subscriptionFactory([databaseEntity$, datagridEntity$], destruction$)
}
