import { concatMap, EMPTY, filter, from, merge, Observable, Subject, switchMap } from "rxjs";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { Calculation, calculationEntity$ } from "./event";
import { CalculationEvent } from "./command";
import { Persistence } from "@taterer/persist";

export function getAllCalculations(): Observable<Calculation> {
  return indexedDB$
  .pipe(
    concatMap(db => db.query(IndexedDBEntity.calculation)),
    switchMap(values => from(values as Calculation[])),
  )
}

export function getCalculationOnce(id, db$?: Observable<Persistence<any, any>>): Observable<Calculation & {
  meta: {
      entity: IndexedDBEntity;
      eventType: CalculationEvent;
  };
}> {
  return merge(
    (db$ ? db$ : indexedDB$).pipe(
      concatMap(db => db.get(IndexedDBEntity.calculation, id))
    ).pipe(filter(i => !!i)),
    new Subject()
  )
}


export function getCalculation(id, db$?: Observable<Persistence<any, any>>): Observable<Calculation & {
  meta: {
      entity: IndexedDBEntity;
      eventType: CalculationEvent;
  };
}> {
  return merge(
    (db$ ? db$ : indexedDB$).pipe(concatMap(db => db.get(IndexedDBEntity.calculation, id))),
    calculationEntity$.pipe(filter(i => i.id === id))
  ).pipe(filter(i => !!i))
}
