import { concatMap, filter, from, merge, Observable, switchMap } from "rxjs";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { Tutorial, tutorialEntity$ } from "./event";
import { TutorialEvent } from "./command";

export function getAllTutorials(): Observable<Tutorial> {
  return indexedDB$
  .pipe(
    concatMap(db => db.query(IndexedDBEntity.tutorial)),
    switchMap(values => from(values as Tutorial[])),
  )
}

export function getTutorial(id): Observable<Tutorial & {
  meta: {
      entity: IndexedDBEntity;
      eventType: TutorialEvent;
  };
}> {
  return merge(
    indexedDB$.pipe(concatMap(db => db.get(IndexedDBEntity.tutorial, id))),
    tutorialEntity$.pipe(filter(i => i.id === id))
  ).pipe(filter(i => !!i))
}
