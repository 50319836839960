import d3 = require("d3")
import { filter, withLatestFrom, map, merge, takeUntil, Observable } from "rxjs"
import { newOverlayDetail } from "../../../domain/displayOverlayDetail/command"
import { mountSVG$, drawShape, moveShapeAggregate, stripShapeFromId, shapeToToolMap } from "../../../domain/shape"
import { moveShape, ShapeEvent } from "../../../domain/shape/command"
import { shapeEntity$ } from "../../../domain/shape/event"
import ShapeOverlay from "../../components/ShapeOverlay"
import { overlayHoverClass } from "../../styles"

export const transformRegex = /translate\((-*[0-9]+), *(-*[0-9]+)\)/

let ogX
let ogY
let deltaX = 0
let deltaY = 0

// Shape listener draws shapes, sets up drag and drop moving abilities, and removes shapes
export function overlay$ (destruction$: Observable<any>) {
  return shapeEntity$
  .pipe(
    filter(shapeEntity => shapeEntity.meta.eventType === ShapeEvent.new),
    withLatestFrom(mountSVG$),
    map(([shape, mount]) => {
      const shapeDestroyed$ = shapeEntity$
      .pipe(
        filter(shapeEntity => shapeEntity.id === shape.id && shapeEntity.meta.eventType === ShapeEvent.remove),
        takeUntil(destruction$)
      )
      const overlayDestruction$ = merge(destruction$, shapeDestroyed$)
      const overlayElement = <ShapeOverlay destruction$={overlayDestruction$} shape={shape} />
      let dragHandler = d3.drag()
      .on("start", dragStart)
      .on('drag', dragged)
      .on('end', dropped)
  
      const path = drawShape(mount, shape)
  
      path.style("cursor", "pointer")
  
      path.on('click', () => {
        newOverlayDetail({ id: stripShapeFromId(shape.id), type: shapeToToolMap[shape.type] })
      })
  
      path.on('mouseover', () => {
        overlayElement.classList.add(overlayHoverClass)
      })
  
      path.on('mouseleave', () => {
        overlayElement.classList.remove(overlayHoverClass)
      })
  
      shapeEntity$
      .pipe(
        filter(shapeEntity => shapeEntity.id === shape.id),
        takeUntil(overlayDestruction$)
      )
      .subscribe({
        next: shapeEntity => {
          path.attr('transform', path.attr('transform').replace(transformRegex, `translate(${shapeEntity.x}, ${shapeEntity.y})`))
          overlayElement.style.left = `${shapeEntity.x}px`
          overlayElement.style.top = `${shapeEntity.y}px`
        },
        complete: () => path.remove()
      })
  
      dragHandler(path)
  
      return overlayElement
    }),
    takeUntil(destruction$)
  )
}

function dragStart (event) {
  const current = d3.select(this);
  const match = current.attr("transform").match(transformRegex)
  if (match) {
    const [m, x, y] = match

  ogX = event.x
  ogY = event.y
  deltaX = parseInt(x) - event.x
  deltaY = parseInt(y) - event.y
  } else {
    console.log('No match!!!')
  }
}

function dragged (event) {
  const current = d3.select(this)
  let x = event.x + deltaX
  let y = event.y + deltaY
  if (x < 40) {
    x = 40
  }
  if (x > window.innerWidth - 40) {
    x = window.innerWidth - 40
  }
  if (y < 40) {
    y = 40
  }
  if (y > window.innerHeight - 32) {
    y = window.innerHeight - 32
  }
  moveShape({
    id: current.attr("id"),
    x,
    y
  })
}

function dropped (event) {
  const current = d3.select(this)
  const match = current.attr("transform").match(transformRegex)
  if (match) {
    const [_match, xString, yString] = match
    const x = parseInt(xString)
    const y = parseInt(yString)
    if (ogX !== event.x || ogY !== event.y) {
      moveShapeAggregate({ id: current.attr('id'), x, y })
    }
  } else {
    console.log('No match!!!')
  }
}
