import { concatMap, Observable, Subject, share, takeUntil, debounceTime } from "rxjs"
import { getColor } from "./getColor"

export function parseToMathML (text: string, variables: string[]): string {
  let res = text
  const sortedVariables = variables.sort((a, b) => b.length - a.length)

  // obfuscate the variables so they don't clash
  for(const [index, variable] of sortedVariables.entries()) {
    res = res.replace(new RegExp(`(${variable})`, 'g'), `#${index}#`)
  }

  // TODO: remove () when the parentheses are for an exponent
  // TODO: figure out \\over for division so that it uses more vertical space than horizontal
  // res = res.replace(/\/\(/g, ' \\over (')

  res = res.replace(/\(/g, '{(')
  res = res.replace(/\)/g, ')}')

  // replace variables back to text wrapped with color and text
  for(const [index, variable] of sortedVariables.entries()) {
    let subscript = variable.match(/^(.*)_([^ ]+)$/)
    if (!subscript) {
      subscript = variable.match(/^([^0-9]+)([0-9]+)$/)
    }
    res = res.replace(
      new RegExp(`#${index}#`, 'g'),
      `\\color{${getColor(variable)}}\\text\{${subscript ? subscript[1] : variable}\}${subscript ? `_{${subscript[2]}}` : ''}\\color{black}`
    )
  }
  return res
}

declare const MathJax

const mathmlTypesetSubject$ = new Subject()

// It's important that only one typesetPromise is invoked at a time
export const mathmlTypeset$ = mathmlTypesetSubject$
  .pipe(
    debounceTime(0),
    concatMap(() => MathJax.typesetPromise()),
    share(),
  )

export function mathmlTypeset () {
  mathmlTypesetSubject$.next(undefined)
}

export function useMathmlTypeset (destruction$: Observable<any>) {
  return mathmlTypeset$
  .pipe(
    takeUntil(destruction$)
  )
  .subscribe()
}
