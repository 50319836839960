import { Persistence } from "@taterer/persist";
import { concatMap, filter, merge, Observable } from "rxjs";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { DatagridEvent } from "./command";
import { Datagrid, datagridEntity$ } from "./event";

export function getDatagrid(id, db$?: Observable<Persistence<any, any>>): Observable<Datagrid & {
  meta: {
      entity: IndexedDBEntity;
      eventType: DatagridEvent;
  };
}> {
  return merge(
    (db$ ? db$ : indexedDB$).pipe(concatMap(db => db.get(IndexedDBEntity.datagrid, id))),
    datagridEntity$.pipe(filter(i => i.id === id)),
  ).pipe(filter(i => !!i))
}
