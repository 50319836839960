import { css } from '@emotion/css'
import { map, takeUntil } from 'rxjs'
import { RouteRegExp } from '../../../domain/route'
import Footer from '../../components/Footer'
import Editor from '../../views/Editor'
import Home from '../../views/Home'
import NotFound from '../../views/NotFound'
import { firstPathChange$ } from './event'
import { subscribeToHistory } from './router'

export default function Router ({ destruction$ }) {
  const route$ = firstPathChange$
  .pipe(
    map(firstPath => {
      if (RouteRegExp.home.test(firstPath)) {
        return (
        <div class={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          width: 100%;
          overflow: auto;
        `}>
          <Home destruction$={firstPathChange$} />
          <Footer />
        </div>)
      } else if (RouteRegExp.edit.test(firstPath)) {
        return (<Editor destruction$={firstPathChange$} />)
      } else {
        return (<NotFound />)
      }
    }),
    takeUntil(destruction$)
  )
  
  subscribeToHistory(destruction$)

  return (
    <div class={css`
      flex-grow: 1;
      height: 1px;
      width: 100%;
    `} single$={route$} />
  )
}
