import { css } from "@emotion/css"
import { Observable } from "rxjs"
import { Shape } from "../../../domain/shape/event"
import Input from "./Input"
import Output from "./Output"
import Center from "./Center"
import { newOverlayDetail } from "../../../domain/displayOverlayDetail/command"
import { shapeToToolMap, stripShapeFromId } from "../../../domain/shape"

export default function ShapeOverlay ({
  destruction$,
  shape
}: {
  destruction$: Observable<any>,
  shape: Shape
}) {
  return (
    <div
      id={`overlay-${shape.id}`}
      style={`position: absolute; top: ${shape.y}px; left: ${shape.x}px;`}
      onclick={() => newOverlayDetail({ id: stripShapeFromId(shape.id), type: shapeToToolMap[shape.type] })}
    >
      <div
        style={`position: relative; width: 150px; height: 150px;`}>
        <div 
          class={css`
            caret-color: transparent;
            display: flex;
            top: -50%;
            left: -50%;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            position: absolute;
          `}>
          <Input destruction$={destruction$} shape={shape} />
          <Center shape={shape} />
          <Output destruction$={destruction$} shape={shape} />
        </div>
      </div>
    </div>
  )
}
