const ignoreList = [
  'undefined',
  'e',
  'Infinity',
  'LN2',
  'LN10',
  'log',
  'LOG2E',
  'LOG10E',
  'pi',
  'phi',
  'sqrt',
  'sqrt2',
  'tau',
  'abs',
  'cbrt',
  'ceil',
  'cube',
  'exp',
  'fix',
  'floor',
  'gcd',
  'hypot',
  'lcm',
  'log10',
  'mod',
  'norm',
  'nthroot',
  'pow',
  'round',
  'sign',
  'mad',
  'max',
  'mean',
  'median',
  'min',
  'mode',
  'prod',
  'std',
  'sum',
  'var',
  'acos',
  'acosh',
  'acot',
  'acoth',
  'acsc',
  'acsch',
  'asec',
  'asech',
  'asin',
  'asinh',
  'atan',
  'atan2',
  'atanh',
  'cos',
  'cosh',
  'cot',
  'coth',
  'csc',
  'csch',
  'sec',
  'sech',
  'sin',
  'sinh',
  'tan',
  'tanh'
]

export default ignoreList
