import { concatMap, filter, from, merge, Observable, switchMap } from "rxjs";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { Split, splitEntity$ } from "./event";
import { SplitEvent } from "./command";

export function getAllSplits(): Observable<Split> {
  return indexedDB$
  .pipe(
    concatMap(db => db.query(IndexedDBEntity.split)),
    switchMap(values => from(values as Split[])),
  )
}

export function getSplit(id): Observable<Split & {
  meta: {
      entity: IndexedDBEntity;
      eventType: SplitEvent;
  };
}> {
  return merge(
    indexedDB$.pipe(concatMap(db => db.get(IndexedDBEntity.split, id))),
    splitEntity$.pipe(filter(i => i.id === id))
  ).pipe(filter(i => !!i))
}
