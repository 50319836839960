import { merge } from "rxjs"
import { eventFactory } from "@taterer/rx-entity"
import { IndexedDBEntity } from "../../persistence/indexedDB"

export enum AggregationEvent {
  new = 'new',
  remove = 'remove',
  rename = 'rename',
  move = 'move',
}
export interface NewAggregation {
  id?: string,
  title?: string,
  x: number,
  y: number,
}

export const [newAggregation$, newAggregation] = eventFactory<NewAggregation>({ entity: IndexedDBEntity.aggregation, eventType: AggregationEvent.new })

export interface RemoveAggregation {
  id: string
}

export const [removeAggregation$, removeAggregation] = eventFactory<RemoveAggregation>({ entity: IndexedDBEntity.aggregation, eventType: AggregationEvent.remove })

export interface RenameAggregation {
  id: string
  title: string
}

export const [renameAggregation$, renameAggregation] = eventFactory<RenameAggregation>({ entity: IndexedDBEntity.aggregation, eventType: AggregationEvent.rename })

export interface MoveAggregation {
  id: string
  x: number
  y: number
}

export const [moveAggregation$, moveAggregation] = eventFactory<MoveAggregation>({ entity: IndexedDBEntity.aggregation, eventType: AggregationEvent.move })

export const aggregationCommands$ = merge(
  newAggregation$,
  removeAggregation$,
  renameAggregation$,
  moveAggregation$,
)
