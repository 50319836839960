import { Tool } from "."
import { subjectFactory } from "@taterer/rx-entity"

export const [setActiveTool$, setActiveTool] = subjectFactory<Tool>(Tool.pointer)

export const [makeTooltipsVisible$, makeTooltipsVisible] = subjectFactory<Boolean>(true)

export type SetActiveToolPanel = {
  id: string
  type: Tool
  collapse: Function
}

export const [setActiveToolPanel$, setActiveToolPanel] = subjectFactory<SetActiveToolPanel | undefined>()
