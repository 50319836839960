import { concatMap, filter, from, merge, Observable, switchMap } from "rxjs";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { Aggregation, aggregationEntity$ } from "./event";
import { AggregationEvent } from "./command";

export function getAllAggregations(): Observable<Aggregation> {
  return indexedDB$
  .pipe(
    concatMap(db => db.query(IndexedDBEntity.aggregation)),
    switchMap(values => from(values as Aggregation[])),
  )
}

export function getAggregation(id): Observable<Aggregation & {
  meta: {
      entity: IndexedDBEntity;
      eventType: AggregationEvent;
  };
}> {
  return merge(
    indexedDB$.pipe(concatMap(db => db.get(IndexedDBEntity.aggregation, id))),
    aggregationEntity$.pipe(filter(i => i.id === id))
  ).pipe(filter(i => !!i))
}
