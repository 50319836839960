import { combineLatestWith, Observable, of, takeUntil } from "rxjs";
import { css, cx } from "@emotion/css";
import { classSync, withAnimationFrame } from "@taterer/rx-jsx";
import { setActiveTool } from "../../../domain/toolSettings/command";
import { activeTool$ } from "../../../domain/toolSettings/event";
import { Tool } from "../../../domain/toolSettings";
import Tooltip from "./Tooltip";

const toolIcon = {
  [Tool.pointer]: 'near_me',
  [Tool.database]: 'web_asset',
  [Tool.calculation]: 'code',
  [Tool.split]: 'call_split',
  [Tool.aggregation]: 'call_merge',
  [Tool.view]: 'pie_chart_outlined',
}

const flippedIconClass = cx('material-icons', css`
  transform: scaleX(-1);
`)

const rotatedIconClass = cx('material-icons', css`
  transform: rotate(90deg);
`)

export default function ControlPanelItem ({
  destruction$,
  tool,
}: {
  destruction$: Observable<any>,
  tool: Tool,
}) {
  const button$ = of(
    // Tutorial documentSelectorId
    <a id={`control-panel-${tool}`} class="btn-large waves-effect waves-light blue" onmousedown={() => setActiveTool(tool)}>
      <i class={tool === Tool.pointer ? flippedIconClass : tool === Tool.aggregation ? rotatedIconClass : tool === Tool.split ? rotatedIconClass : 'material-icons'}>{toolIcon[tool]}</i>
    </a>
  )

  activeTool$
  .pipe(
    combineLatestWith(button$),
    withAnimationFrame,
    takeUntil(destruction$),
  )
  .subscribe(([activeTool, button]) => {
    classSync(button, 'blue', activeTool !== tool)
  })

  return (
    <div class={css`
      pointer-events: stroke;
      width: fit-content;
      position: relative;
      left: 0px;

      :hover div {
        display: block;
      }
    `}>
      <Tooltip tool={tool} />
      <div single$={button$} />
    </div>
  )
}
