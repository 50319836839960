import { map, Observable, of, takeUntil, withLatestFrom } from "rxjs";
import { Datagrid } from "../../../domain/datagrid/event";
import { getDatagrid } from "../../../domain/datagrid/query";
import { stripShapeFromId } from "../../../domain/shape";
import { Shape } from "../../../domain/shape/event";
import { FlowSource } from "../../../domain/flow/command";
import { IndexedDBEntity } from "../../../persistence/indexedDB";
import { outputClass, overlayHoverClass } from "../../styles";
import OutputArrow from '../OutputArrow'
import { classSync, fromEventElement$ } from "@taterer/rx-jsx";

type Output = {
  index: number,
  title: string,
}

function getDatagridOutputs (datagrid: Datagrid): Output[] {
  return datagrid.columnStyle.filter((_, index) => index !== 0).map((column, index) => ({ index, title: column.name }))
}

function setTransferData (event, data: FlowSource) {
  event.dataTransfer.setData('text', JSON.stringify(data))
}

export default function DatabaseOutput ({ destruction$, shape }: { destruction$: Observable<any>, shape: Shape }) {
  const id = stripShapeFromId(shape.id)

  const outputs$ = getDatagrid(id)
  .pipe(
    map(getDatagridOutputs),
    map(outputs => <div>
          {outputs.map(output => {
          const span$ = of(
            <span
              id={`output-${output.index + 1}-${id}`}
              class={outputClass}
              draggable="true"
              ondragend={() => document.body.classList.remove('output-drag')}
              ondragstart={event => {
                document.body.classList.add('output-drag')
                setTransferData(event, {
                  sourceEntity: IndexedDBEntity.database,
                  sourceId: id,
                  sourceIndex: output.index + 1,
                  sourceTitle: output.title,
                })
              }}
              >
                <OutputArrow />
                {output.title}
            </span>
          )
    
          fromEventElement$(span$, 'mouseover')
          .pipe(
            withLatestFrom(span$),
            takeUntil(destruction$)
          )
          .subscribe(([_event, span]) => {
            classSync(span, overlayHoverClass, true)
          })
          fromEventElement$(span$, 'mouseleave')
          .pipe(
            withLatestFrom(span$),
            takeUntil(destruction$)
          )
          .subscribe(([_event, span]) => {
            classSync(span, overlayHoverClass, false)
          })
          return (
            <div>
              <div style={`padding: 12px 0px;`} single$={span$} />
            </div>
          )
        })}
        </div>),
    takeUntil(destruction$)
  )

  return (
    <div single$={outputs$} />
  )
}
