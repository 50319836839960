import { from, Observable, Subscription } from 'rxjs'
import { map, shareReplay, takeUntil, withLatestFrom } from 'rxjs/operators'
import { indexedDBFactory } from '@taterer/persist-indexed-db'
import { concatMapPersist, concatMapRemove, Persistable, Persistence } from '@taterer/persist'

export enum IndexedDBEntity {
  aggregation = 'aggregation',
  database = 'database',
  calculation = 'calculation',
  split = 'split',
  view = 'view',
  datagrid = 'datagrid',
  flow = 'flow',
  tutorial = 'tutorial',
}

async function indexedDBPersistence (): Promise<Persistence<any & Persistable, IndexedDBEntity>> {
  const databaseName = 'db-tater-calc'

  try {
    // Increment the version number anytime the database schema changes
    const indexedDB = await indexedDBFactory(databaseName, 2, [
      {
        name: IndexedDBEntity.aggregation,
        options: {
          keyPath: 'id'
        }
      },
      {
        name: IndexedDBEntity.database,
        options: {
          keyPath: 'id'
        }
      },
      {
        name: IndexedDBEntity.calculation,
        options: {
          keyPath: 'id'
        }
      },
      {
        name: IndexedDBEntity.split,
        options: {
          keyPath: 'id'
        }
      },
      {
        name: IndexedDBEntity.view,
        options: {
          keyPath: 'id'
        }
      },
      {
        name: IndexedDBEntity.datagrid,
        options: {
          keyPath: 'id'
        }
      },
      {
        name: IndexedDBEntity.flow,
        options: {
          keyPath: 'id'
        }
      },
      {
        name: IndexedDBEntity.tutorial,
        options: {
          keyPath: 'id'
        }
      },
    ])

    return indexedDB
  } catch (err) {
    console.log('Failed to create indexedDB persistence.', err)
    // return localstorage version
  }
}

export const indexedDB$ = from(indexedDBPersistence()).pipe(shareReplay(1))

export function withIndexedDb<Entity> () {
  return withLatestFrom<Entity, [Persistence<any & Persistable, IndexedDBEntity>]>(indexedDB$)
}

export function subscribeAndPersist<T> (
  destruction$: Observable<any>,
  tableName: IndexedDBEntity,
  mapFunction: (value: T) => T & Persistable,
  observable$: Observable<T>,
  ): Subscription {

  return observable$
  .pipe(
    map(mapFunction),
    withIndexedDb(),
    concatMapPersist(tableName),
    takeUntil(destruction$)
  )
  .subscribe()
}

export function subscribeAndRemove<T> (
  destruction$: Observable<any>,
  tableName: IndexedDBEntity,
  mapFunction: (value: T) => Persistable | { id: string } | string,
  observable$: Observable<T>,
  ): Subscription {

  return observable$
  .pipe(
    map<any, any>(mapFunction),
    withIndexedDb(),
    concatMapRemove(tableName),
    takeUntil(destruction$)
  )
  .subscribe()
}
