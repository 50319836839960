import { css } from "@emotion/css";

export default function () {
  return (
    <div class={`to-hide ${css`
      position: absolute;
      z-index: -1;
      top: -5px;
      left: -5px;
      width: calc(100% + 10px);
      height: calc(100% + 10px);
      background-color: #add8e699;
      border-radius: 10px;
      color: #add8e699;
    `}`}>
      <i style='position: absolute; left: 100%; top: -5px; font-size: 60px;' class="material-icons dp48">arrow_forward</i>
    </div>
  )
}
