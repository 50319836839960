import { Observable, shareReplay } from "rxjs";
import {
  SideEventHandlers,
  EntityEventHandlers,
  entityServiceFactory,
  subscriptionFactory
} from "@taterer/rx-entity";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { datagridEntity$ } from '../datagrid/event';
import { NewFlow, flowCommands$, FlowEvent, RemoveFlow, SyncFlow } from './command';
import { newLine, removeLine } from '../line/command';
import { generateShapeId, toolToShapeMap } from '../shape';

export interface Flow {
  id: string
  deleted?: boolean
  created_at: number
  sourceId: string,
  sourceEntity: IndexedDBEntity,
  sourceIndex: number,
  sourceTitle: string,
  destinationId: string,
  destinationEntity: IndexedDBEntity,
  destinationIndex: number,
  destinationTitle: string,
}

const flowEventHandlers: EntityEventHandlers<Flow, FlowEvent> = {
  [FlowEvent.new]: (_, event: NewFlow) => {
    return {
      id: `${event.sourceIndex}-${event.sourceId}-${event.destinationIndex}-${event.destinationId}`,
      created_at: Date.now(),
      ...event,
    }
  },
  [FlowEvent.remove]: (entity, event: RemoveFlow) => {
    return {
      ...entity,
      deleted: true
    }
  },
  [FlowEvent.sync]: (entity, event: SyncFlow) => {
    return {
      ...entity,
      ...event,
    }
  },
}

const flowAggregateHandlers: SideEventHandlers<Flow, FlowEvent> = {
  [FlowEvent.new]: (_, event: NewFlow, updatedEntity): Flow => {
    newLine({
      id: `${event.sourceIndex}-${event.sourceId}-${event.destinationIndex}-${event.destinationId}`,
      destinationId: event.destinationId,
      destinationElementId: `input-${event.destinationIndex}-${event.destinationId}`,
      destinationShapeId: generateShapeId(event.destinationId, toolToShapeMap[event.destinationEntity]),
      sourceId: event.sourceId,
      sourceElementId: `output-${event.sourceIndex}-${event.sourceId}`,
      sourceShapeId: generateShapeId(event.sourceId, toolToShapeMap[event.sourceEntity]),
      color: '#2196f3',
    })
    return updatedEntity
  },
  [FlowEvent.remove]: (_, event, updatedEntity) => {
    removeLine({
      id: event.id
    })
    return updatedEntity
  },
}

export const flowEntity$ = flowCommands$
  .pipe(
    entityServiceFactory<Flow, any>(
      indexedDB$,
      IndexedDBEntity.flow,
      flowEventHandlers,
      flowAggregateHandlers
    ),
    shareReplay(1)
  )

export function subscribeToFlowServices (destruction$: Observable<any>) {
  subscriptionFactory([flowEntity$, datagridEntity$], destruction$)
}
