import { map, Observable, of, takeUntil, withLatestFrom } from "rxjs";
import { Calculation, } from "../../../domain/calculation/event";
import { getCalculation } from "../../../domain/calculation/query";
import { stripShapeFromId } from "../../../domain/shape";
import { Shape } from "../../../domain/shape/event";
import { FlowSource } from "../../../domain/flow/command";
import { IndexedDBEntity } from "../../../persistence/indexedDB";
import { outputClass, overlayHoverClass } from "../../styles";
import { classSync, fromEventElement$ } from "@taterer/rx-jsx";
import OutputArrow from "../OutputArrow";

type Output = {
  index: number,
  title: string,
}

function getCalculationOutputs (calculation: Calculation): Output[] {
  if (!calculation.output) {
    return []
  }
  return [{ index: 0, title: calculation.output }]
}

function setTransferData (event, data: FlowSource) {
  event.dataTransfer.setData('text', JSON.stringify(data))
}

export default function CalculationOutput ({ destruction$, shape }: { destruction$: Observable<any>, shape: Shape }) {
  const id = stripShapeFromId(shape.id)

  const outputs$ = getCalculation(id)
  .pipe(
    map(getCalculationOutputs),
    map(outputs => {
      return (
        <div>
          {outputs.map(output => {
            const span$ = of(
              <span
                id={`output-${output.index}-${id}`}
                class={outputClass}
                draggable="true"
                ondragend={() => document.body.classList.remove('output-drag')}
                ondragstart={event => {
                  document.body.classList.add('output-drag')
                  setTransferData(event, {
                    sourceEntity: IndexedDBEntity.calculation,
                    sourceId: id,
                    sourceIndex: output.index,
                    sourceTitle: output.title,
                  })
                }}
                >
                  <OutputArrow />
                  {output.title}
                </span>
              )

            fromEventElement$(span$, 'mouseover')
            .pipe(
              withLatestFrom(span$),
              takeUntil(destruction$)
            )
            .subscribe(([_event, span]) => {
              classSync(span, overlayHoverClass, true)
            })
            fromEventElement$(span$, 'mouseleave')
            .pipe(
              withLatestFrom(span$),
              takeUntil(destruction$)
            )
            .subscribe(([_event, span]) => {
              classSync(span, overlayHoverClass, false)
            })
            
            return (
              <div>
                <div style={`padding: 12px 0px;`} single$={span$} />
              </div>
            )
          })}
        </div>
      )
    }),
    takeUntil(destruction$)
  )

  return (
    <div single$={outputs$} />
  )
}
