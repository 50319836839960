import { concatMap, filter, from, merge, Observable, switchMap } from "rxjs";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { View, viewEntity$ } from "./event";
import { ViewEvent } from "./command";

export function getAllViews(): Observable<View> {
  return indexedDB$
  .pipe(
    concatMap(db => db.query(IndexedDBEntity.view)),
    switchMap(values => from(values as View[])),
  )
}

export function getView(id): Observable<View & {
  meta: {
      entity: IndexedDBEntity;
      eventType: ViewEvent;
  };
}> {
  return merge(
    indexedDB$.pipe(concatMap(db => db.get(IndexedDBEntity.view, id))),
    viewEntity$.pipe(filter(i => i.id === id))
  ).pipe(filter(i => !!i))
}
