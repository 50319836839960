import { css } from "@emotion/css"
import { Route } from "../../../domain/route"
import { pushHistory } from "../../components/Router/command"

const pinClass = css`
  margin-right: 20px;
`

const smallTableHeaderClass = css`
  padding-top: 80px;
`

export default function Home ({ destruction$ }) {
  return (
    <div style={`
      max-width: 800px;
      padding: 10px;
      font-size: 20px;
    `}>
      <h1>Tater Flow</h1>
      <br />
      <h5 class={css`
        display: flex;
        flex-direction: row;
      `}>
        <div class={pinClass}>low-code</div>
        <div class={pinClass}>flowchart</div>
        <div class={pinClass}>flow-based-programming</div>
      </h5>
      <div style='height: 170px; width: 800px; max-width: calc(100vw - 20px); position: relative; overflow-x: auto; overflow-y: hidden;'>
        <div style='position: absolute;'>
          <img src="/flow/images/flowexample.jpg" alt='Tater Flow' />
        </div>
      </div>
      <p>For knowledge workers.</p>
      <br />
      <br />
      <br />
      <div style='display: flex; justify-content: center;'>
        <a
          class='btn btn-large blue waves-effect waves-light'
          style='z-index: 0;'
          onClick={event => {
            if (!event.ctrlKey) {
              pushHistory({ url: `/${Route.edit}` })
              event.preventDefault()
            }
          }} href={`/${Route.edit}`}>Get Started</a>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <p>Compare solutions for solving problems.</p>
      <p>
        <table class={css`
          @media (max-width: 699px) {
            display: none;
          }
        `}>
          <tr>
            <td></td>
            <td>Database</td>
            <td>Tater Flow</td>
            <td>Spreadsheet</td>
          </tr>
          <tr>
            <td>User</td>
            <td style='background-color: #ff7a7a;'>Developer</td>
            <td style='background-color: lightgreen;'>Knowledge Worker</td>
            <td style='background-color: lightgreen;'>Knowledge Worker</td>
          </tr>
          <tr>
            <td>Time to Build</td>
            <td style='background-color: #ff7a7a;'>Long</td>
            <td style='background-color: lightgreen;'>Short</td>
            <td style='background-color: lightgreen;'>Short</td>
          </tr>
          <tr>
            <td>Maintainability</td>
            <td>Maybe</td>
            <td style='background-color: lightgreen;'>Good</td>
            <td style='background-color: #ff7a7a;'>Poor</td>
          </tr>
          <tr>
            <td>Reusability</td>
            <td>Maybe</td>
            <td style='background-color: lightgreen;'>Good</td>
            <td style='background-color: #ff7a7a;'>Poor</td>
          </tr>
          <tr>
            <td>
              Machine Learning &<br />
              Artifical Intelligence
            </td>
            <td>Maybe</td>
            <td>Coming soon(TM)</td>
            <td style='background-color: #ff7a7a;'>Poor</td>
          </tr>
          <tr>
            <td>Scaling</td>
            <td style='background-color: lightgreen;'>Good</td>
            <td>TBD</td>
            <td style='background-color: #ff7a7a;'>Poor</td>
          </tr>
          <tr>
            <td>Organization</td>
            <td>Tables</td>
            <td>Spatial/Hierarchical</td>
            <td>Tabs</td>
          </tr>
        </table>
        <div class={css`
          @media (min-width: 700px) {
            display: none;
          }
        `}>
          <table>
            <tr>
              <td colspan='2'>User</td>
            </tr>
            <tr>
              <td>Database</td>
              <td style='background-color: #ff7a7a;'>Developer</td>
            </tr>
            <tr>
              <td>Tater Flow</td>
              <td style='background-color: lightgreen;'>Knowledge Worker</td>
            </tr>
            <tr>
              <td>Spreadsheet</td>
              <td style='background-color: lightgreen;'>Knowledge Worker</td>
            </tr>
            <tr>
              <td class={smallTableHeaderClass} colspan='2'>Time to Build</td>
            </tr>
            <tr>
              <td>Database</td>
              <td style='background-color: #ff7a7a;'>Long</td>
            </tr>
            <tr>
              <td>Tater Flow</td>
              <td style='background-color: lightgreen;'>Short</td>
            </tr>
            <tr>
              <td>Spreadsheet</td>
              <td style='background-color: lightgreen;'>Short</td>
            </tr>
            <tr>
              <td class={smallTableHeaderClass} colspan='2'>Maintainability</td>
            </tr>
            <tr>
              <td>Database</td>
              <td>Maybe</td>
            </tr>
            <tr>
              <td>Tater Flow</td>
              <td style='background-color: lightgreen;'>Good</td>
            </tr>
            <tr>
              <td>Spreadsheet</td>
              <td style='background-color: #ff7a7a;'>Poor</td>
            </tr>
            <tr>
              <td class={smallTableHeaderClass} colspan='2'>Reusability</td>
            </tr>
            <tr>
              <td>Database</td>
              <td>Maybe</td>
            </tr>
            <tr>
              <td>Tater Flow</td>
              <td style='background-color: lightgreen;'>Good</td>
            </tr>
            <tr>
              <td>Spreadsheet</td>
              <td style='background-color: #ff7a7a;'>Poor</td>
            </tr>
            <tr>
              <td class={smallTableHeaderClass} colspan='2'>Machine Learning & Artifical Intelligence</td>
            </tr>
            <tr>
              <td>Database</td>
              <td>Maybe</td>
            </tr>
            <tr>
              <td>Tater Flow</td>
              <td>Coming soon(TM)</td>
            </tr>
            <tr>
              <td>Spreadsheet</td>
              <td style='background-color: #ff7a7a;'>Poor</td>
            </tr>
            <tr>
              <td class={smallTableHeaderClass} colspan='2'>Scaling</td>
            </tr>
            <tr>
              <td>Database</td>
              <td style='background-color: lightgreen;'>Good</td>
            </tr>
            <tr>
              <td>Tater Flow</td>
              <td>TBD</td>
            </tr>
            <tr>
              <td>Spreadsheet</td>
              <td style='background-color: #ff7a7a;'>Poor</td>
            </tr>
            <tr>
              <td class={smallTableHeaderClass} colspan='2'>Organization</td>
            </tr>
            <tr>
              <td>Database</td>
              <td>Tables</td>
            </tr>
            <tr>
              <td>Tater Flow</td>
              <td>Spatial/Hierarchical</td>
            </tr>
            <tr>
              <td>Spreadsheet</td>
              <td>Tabs</td>
            </tr>
          </table>
        </div>
      </p>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  )
}
