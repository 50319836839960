import { eventFactory } from "@taterer/rx-entity"
import { merge } from "rxjs"
import { MemoryEntity } from "../../persistence/memoryDB"
import { Tool } from "../toolSettings"

export enum DisplayOverlayDetailEvent {
  new = 'new',
  remove = 'remove',
}

export interface NewOverlayDetail {
  id: string
  type: Tool
}

export const [newOverlayDetail$, newOverlayDetail] = eventFactory<NewOverlayDetail>({ entity: MemoryEntity.displayOverlayDetail, eventType: DisplayOverlayDetailEvent.new })

export interface RemoveOverlayDetail {
  id: string
}

export const [removeOverlayDetail$, removeOverlayDetail] = eventFactory<RemoveOverlayDetail>({ entity: MemoryEntity.displayOverlayDetail, eventType: DisplayOverlayDetailEvent.remove })

export const displayOverlayDetailCommands$ = merge(
  newOverlayDetail$,
  removeOverlayDetail$,
)
