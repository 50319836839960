import { merge } from "rxjs"
import { eventFactory } from "@taterer/rx-entity"
import { IndexedDBEntity } from "../../persistence/indexedDB"

export enum SplitEvent {
  new = 'new',
  remove = 'remove',
  rename = 'rename',
  move = 'move',
}

export interface NewSplit {
  id?: string,
  title?: string,
  x: number,
  y: number,
}

export const [newSplit$, newSplit] = eventFactory<NewSplit>({ entity: IndexedDBEntity.split, eventType: SplitEvent.new })

export interface RemoveSplit {
  id: string
}

export const [removeSplit$, removeSplit] = eventFactory<RemoveSplit>({ entity: IndexedDBEntity.split, eventType: SplitEvent.remove })

export interface RenameSplit {
  id: string
  title: string
}

export const [renameSplit$, renameSplit] = eventFactory<RenameSplit>({ entity: IndexedDBEntity.split, eventType: SplitEvent.rename })

export interface MoveSplit {
  id: string
  x: number
  y: number
}

export const [moveSplit$, moveSplit] = eventFactory<MoveSplit>({ entity: IndexedDBEntity.split, eventType: SplitEvent.move })

export const splitCommands$ = merge(
  newSplit$,
  removeSplit$,
  renameSplit$,
  moveSplit$,
)
