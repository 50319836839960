import { merge } from "rxjs"
import { eventFactory } from "@taterer/rx-entity"
import { IndexedDBEntity } from "../../persistence/indexedDB"

export enum TutorialEvent {
  new = 'new',
  remove = 'remove',
  skip = 'skip',
  continue = 'continue',
  restart = 'restart',
  completeStep = 'completeStep',
}

export interface NewTutorial {
  id: string
}

export const [newTutorial$, newTutorial] = eventFactory<NewTutorial>({ entity: IndexedDBEntity.tutorial, eventType: TutorialEvent.new })

export interface RemoveTutorial {
  id: string
}

export const [removeTutorial$, removeTutorial] = eventFactory<RemoveTutorial>({ entity: IndexedDBEntity.tutorial, eventType: TutorialEvent.remove })

export interface SkipTutorial {
  id: string
}

export const [skipTutorial$, skipTutorial] = eventFactory<SkipTutorial>({ entity: IndexedDBEntity.tutorial, eventType: TutorialEvent.skip })

export interface ContinueTutorial {
  id: string
}

export const [continueTutorial$, continueTutorial] = eventFactory<ContinueTutorial>({ entity: IndexedDBEntity.tutorial, eventType: TutorialEvent.continue })

export interface RestartTutorial {
  id: string
}

export const [restartTutorial$, restartTutorial] = eventFactory<RestartTutorial>({ entity: IndexedDBEntity.tutorial, eventType: TutorialEvent.restart })

export interface CompleteTutorialStep {
  id: string
}

export const [completeTutorialStep$, completeTutorialStep] = eventFactory<CompleteTutorialStep>({ entity: IndexedDBEntity.tutorial, eventType: TutorialEvent.completeStep })

export const tutorialCommands$ = merge(
  newTutorial$,
  removeTutorial$,
  skipTutorial$,
  continueTutorial$,
  restartTutorial$,
  completeTutorialStep$,
)
