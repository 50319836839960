import { shareReplay } from 'rxjs';
import { v4 as uuid } from 'uuid';
import { RowStyle, ColumnStyle } from ".";
import { EntityEventHandlers, entityServiceFactory } from "@taterer/rx-entity";
import { indexedDB$, IndexedDBEntity } from '../../persistence/indexedDB';
import {
  DatagridEvent,
  NewDatagrid,
  ResizeDatagrid,
  AddRow,
  RemoveRow,
  MoveRow,
  AddColumn,
  RemoveColumn,
  MoveColumn,
  ResizeColumn,
  HideColumn,
  SetCell,
  RemoveDatagrid,
  datagridCommands$,
  RenameColumn,
} from "./command";
import { tag } from '@taterer/rxjs-debugger';

export interface Datagrid {
  id: string
  deleted?: boolean
  dataset: string[][]
  rowStyle: RowStyle[]
  columnStyle: ColumnStyle[]
  height: number
  width: number
}

export const datagridEventHandlers: EntityEventHandlers<Datagrid, DatagridEvent> = {
  [DatagridEvent.new]: (entity, event: NewDatagrid): Datagrid => {
    return {
      id: event.id,
      dataset: event.dataset || entity?.dataset || [[uuid(), '1']],
      rowStyle: event.rowStyle || entity?.rowStyle || [],
      columnStyle: event.columnStyle || entity?.columnStyle || [{ name: 'id', hidden: true }, { name: 'Data' }],
      height: event.height || entity?.height || 400,
      width: event.width || entity?.width || 400,
    }
  },
  [DatagridEvent.remove]: (entity, event: RemoveDatagrid): Datagrid => {
    return {
      ...entity,
      deleted: true
    }
  },
  [DatagridEvent.resize]: (entity, event: ResizeDatagrid): Datagrid => {
    return {
      ...entity,
      height: event.height || entity.height,
      width: event.width || entity.width,
    }
  },
  [DatagridEvent.addRow]: (entity, event: AddRow): Datagrid => {
    const res = { ...entity, dataset: [...entity.dataset] }
    const row = new Array(entity.columnStyle.length).fill('')
    row[0] = event.rowId || uuid()
    if (event.rowIndex !== undefined) {
      res.dataset.splice(event.rowIndex, 0, row)
    } else {
      res.dataset.push(row)
    }
    return res
  },
  [DatagridEvent.removeRow]: (entity, event: RemoveRow): Datagrid => {
    const res = { ...entity, dataset: [...entity.dataset] }
    let rowIndex
    if (event.rowId !== undefined) {
      rowIndex = res.dataset.findIndex(i => i[0] === event.rowId)
    } else {
      rowIndex = entity.dataset.length - 1
    }
    res.dataset.splice(rowIndex, 1)
    return res
  },
  [DatagridEvent.moveRow]: (entity, event: MoveRow): Datagrid => {
    const res = { ...entity, dataset: [...entity.dataset] }
    const rowIndex = res.dataset.findIndex(i => i[0] === event.rowId)
    const movedRows = res.dataset.splice(rowIndex, 1)
    res.dataset.splice(event.rowIndex, 0, ...movedRows)
    return res
  },
  [DatagridEvent.addColumn]: (entity, event: AddColumn): Datagrid => {
    const res = { ...entity }
    res.dataset = entity.dataset.map(originalRow => {
      const row = [...originalRow]
      if (event.index !== undefined) {
        row.splice(event.index || 1, 0, '')
      } else {
        row.push('')
      }
      return row
    })
    res.columnStyle = [...entity.columnStyle]
    if (event.index !== undefined) {
      res.columnStyle.splice(event.index || 1, 0, { name: event.title })
    } else {
      res.columnStyle.push({ name: event.title })
    }
    return res
  },
  [DatagridEvent.removeColumn]: (entity, event: RemoveColumn): Datagrid => {
    if (event.index === 0) {
      throw new Error('The index column should not be removed from datasets')
    }
    const indexToRemove = !event.index ? entity.dataset[0].length - 1 : event.index
    const res = { ...entity }
    res.dataset = entity.dataset.map(originalRow => {
      const row = [...originalRow]
      row.splice(indexToRemove, 1)
      return row
    })
    res.columnStyle = [...entity.columnStyle]
    res.columnStyle.splice(indexToRemove, 1)
    return res
  },
  [DatagridEvent.renameColumn]: (entity, event: RenameColumn): Datagrid => {
    const res = { ...entity }
    res.columnStyle = [...entity.columnStyle]
    res.columnStyle[event.index].name = event.title
    return res
  },
  [DatagridEvent.moveColumn]: (entity, event: MoveColumn): Datagrid => {
    const res = { ...entity }
    res.dataset = entity.dataset.map(originalRow => {
      const row = [...originalRow]
      const movedCells = row.splice(event.index, 1)
      row.splice(event.targetIndex || 1, 0, ...movedCells)
      return row
    })
    res.columnStyle = [...entity.columnStyle]
    const movedStyles = res.columnStyle.splice(event.index, 1)
    res.columnStyle.splice(event.targetIndex || 1, 0, ...movedStyles)
    return res
  },
  [DatagridEvent.resizeColumn]: (entity, event: ResizeColumn): Datagrid => {
    const res = { ...entity }
    res.columnStyle = [...entity.columnStyle]
    res.columnStyle[event.index] = { ...entity.columnStyle[event.index], width: event.width }
    return res
  },
  [DatagridEvent.hideColumn]: (entity, event: HideColumn): Datagrid => {
    const res = { ...entity }
    res.columnStyle = [...entity.columnStyle]
    res.columnStyle[event.index] = { ...entity.columnStyle[event.index], hidden: event.hidden }
    if (!event.hidden) {
      delete res.columnStyle[event.index].hidden
    }
    return res
  },
  [DatagridEvent.setCell]: (entity, event: SetCell): Datagrid => {
    const res = { ...entity, dataset: [ ...entity.dataset ] }
    res.dataset[event.rowIndex] = [...entity.dataset[event.rowIndex]]
    res.dataset[event.rowIndex][event.index] = event.value
    return res
  }
}

export const datagridEntity$ = datagridCommands$
  .pipe(
    entityServiceFactory(
      indexedDB$,
      IndexedDBEntity.datagrid,
      datagridEventHandlers
    ),
    shareReplay(1),
  )
