import { v4 as uuid } from 'uuid'
import { Observable, shareReplay } from "rxjs";
import {
  SideEventHandlers,
  EntityEventHandlers,
  entityServiceFactory,
  subscriptionFactory
} from "@taterer/rx-entity";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { newShape, removeShape } from '../shape/command';
import {
  NewSplit,
  splitCommands$,
  SplitEvent,
  MoveSplit,
  RemoveSplit,
  RenameSplit
} from './command';
import { generateShapeId, toolToShapeMap } from '../shape';

export enum ComparisonOperator {
  '==' = '==',
  '!=' = '!=',
  '!!' = '!!',
  '>' = '>',
  '>=' = '>=',
  '<' = '<',
  '<=' = '<=',
}

export const ComparisonOperatorDescription: {
  [key in ComparisonOperator]: string
} = {
  '==': 'is equal to',
  '!=': 'is not equal to',
  '!!': 'exists',
  '>': 'is greater than',
  '>=': 'is greater than or equal to',
  '<': 'is less than',
  '<=': 'is less than or equal to',
}

export interface DecisionBranch {
  leftIndex: string
  comparison: ComparisonOperator
  rightIndex: string
}

export interface Split {
  id: string
  deleted?: boolean
  created_at: number
  x: number
  y: number
  title?: string
  branches: DecisionBranch[]
}

const splitEventHandlers: EntityEventHandlers<Split, SplitEvent> = {
  [SplitEvent.new]: (_, event: NewSplit) => {
    return {
      id: event.id || uuid(),
      created_at: Date.now(),
      branches: [],
      ...event,
    }
  },
  [SplitEvent.remove]: (entity, event: RemoveSplit) => {
    return {
      ...entity,
      deleted: true
    }
  },
  [SplitEvent.rename]: (entity, event: RenameSplit) => {
    return {
      ...entity,
      title: event.title
    }
  },
  [SplitEvent.move]: (entity, event: MoveSplit) => {
    return {
      ...entity,
      x: event.x || entity.x,
      y: event.y || entity.y,
    }
  },
}

const splitSideEventHandlers: SideEventHandlers<Split, SplitEvent> = {
  [SplitEvent.new]: (_, event: NewSplit, updatedEntity): Split => {
    newShape({
      id: generateShapeId(updatedEntity.id, toolToShapeMap.split),
      x: event.x,
      y: event.y,
      type: toolToShapeMap.split,
    })
    return updatedEntity
  },
  [SplitEvent.remove]: (_, event, updatedEntity) => {
    removeShape({ id: generateShapeId(event.id, toolToShapeMap.split) })
    return updatedEntity
  },
}

export const splitEntity$ = splitCommands$
  .pipe(
    entityServiceFactory<Split, any>(
      indexedDB$,
      IndexedDBEntity.split,
      splitEventHandlers,
      splitSideEventHandlers
    ),
    shareReplay(1)
  )

export function subscribeToSplitServices (destruction$: Observable<any>) {
  subscriptionFactory([splitEntity$], destruction$)
}

export function checkBranch (branch: DecisionBranch, rowData: any[]) {
  const left = rowData[branch.leftIndex]
  const right = rowData[branch.rightIndex]
  switch (branch.comparison) {
    case ComparisonOperator['==']:
      return left == right
    case ComparisonOperator['!=']:
      return left != right
    case ComparisonOperator['!!']:
      return left !== undefined && left !== null && left !== ''
    case ComparisonOperator['>']:
      return left > right
    case ComparisonOperator['>=']:
      return left >= right
    case ComparisonOperator['<']:
      return left < right
    case ComparisonOperator['<=']:
      return left <= right
    default:
      return true
  }
}
