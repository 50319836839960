import { css } from "@emotion/css";
import { ShapeType } from "../../../domain/shape/command";

export default function ({ shape }) {
  return (
    <div class={css`
      margin: 20px;
      color: white;
    `}>
      <div class="to-hide">
        <i class="material-icons dp48">{
          shape.type === ShapeType.circle ? 'visibility' :
          'edit'
        }</i>
      </div>
    </div>
  )
}
