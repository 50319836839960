import { filter, map, Observable, share, takeUntil } from 'rxjs'
import { css } from '@emotion/css'
import { RowStyle, ColumnStyle } from '../../../domain/datagrid'
import Row from './Row'
import { Datagrid as DatagridType } from '../../../domain/datagrid/event'
import { DatagridEvent } from '../../../domain/datagrid/command'
import { getDatagrid } from '../../../domain/datagrid/query'

export default function Datagrid ({ destruction$, id, headerStyle }: {
  destruction$: Observable<any>,
  id: string,
  headerStyle?: ColumnStyle
}) {
  const datagridById$ = getDatagrid(id)
  .pipe(
    share(),
  )

  const datagridElement$ = datagridById$
  .pipe(
    filter(datagrid =>
      !datagrid.meta ||
      datagrid.meta.eventType === DatagridEvent.new ||
      datagrid.meta.eventType === DatagridEvent.addColumn ||
      datagrid.meta.eventType === DatagridEvent.addRow),
    map(datagrid => {
      return <DatagridElement destruction$={destruction$}
        id={id}
        dataset={datagrid.dataset}
        rowStyle={datagrid.rowStyle}
        columnStyle={datagrid.columnStyle}
        headerStyle={headerStyle}
        datagridById$={datagridById$}
        />
    }),
    takeUntil(destruction$)
  )

  return (
    <div single$={datagridElement$} />
  )
}

const defaultHeaderStyle = css`
  background-color: #e7e7e7;
`

function DatagridElement ({ destruction$, id, dataset, rowStyle, columnStyle = [], headerStyle, datagridById$ }: {
  destruction$: Observable<any>,
  id: string,
  dataset: string[][],
  rowStyle?: RowStyle[],
  columnStyle?: ColumnStyle[],
  headerStyle?: ColumnStyle,
  datagridById$: Observable<DatagridType>
}) {
  return (
    <div
      class={css`
        caret-color: auto;
        display: grid;
        border: lightgray 2px solid;
        overflow-x: auto;
        max-width: calc(100vw - 80px);
      `}
    >
      <div>
        <Row destruction$={destruction$}
          datagridId={id}
          datagridById$={datagridById$}
          rowIndex={-1}
          row={columnStyle.map(style => style.name)}
          columnStyle={columnStyle}
          rowStyle={headerStyle ? headerStyle : { class: defaultHeaderStyle }}
          />
      </div>
      <div style='width: fit-content; max-height: calc(100vh - 150px); overflow-y: auto;'>
        {dataset.map((row, index) => <Row
          destruction$={destruction$}
          datagridById$={datagridById$}
          datagridId={id}
          rowIndex={index}
          row={row}
          columnStyle={columnStyle}
          rowStyle={rowStyle ? rowStyle[index] : undefined}
          />)}
      </div>
    </div>
  )
}
