import { css } from "@emotion/css"
import {
  distinctUntilChanged,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  share,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from "rxjs"
import { classSync, withAnimationFrame } from "@taterer/rx-jsx"
import { Tool } from "../../../domain/toolSettings"
import { setActiveToolPanel } from "../../../domain/toolSettings/command"
import { activeToolPanel$ } from "../../../domain/toolSettings/event"
import { removeOverlayDetail } from "../../../domain/displayOverlayDetail/command"
import { getSplit } from "../../../domain/split/query"
import { newDatagrid } from "../../../domain/datagrid/command"
import { datagridEntity$ } from "../../../domain/datagrid/event"
import { makeItFlow } from "../../../domain/flow"
import { flowEntity$ } from "../../../domain/flow/event"
import { getAllFlowsArray } from "../../../domain/flow/query"
import { withIndexedDb } from "../../../persistence/indexedDB"
import { getDatagrid } from "../../../domain/datagrid/query"
import { ComparisonOperatorDescription } from "../../../domain/split/event"
import { removeSplit } from "../../../domain/split/command"
import { overlayToolsClass, toolItem } from "../../styles"

declare const M;

const detailClass = css`
  background-color: #add8e6ee;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
`

const activeDetailClass = css`
  background-color: #eafaff !important;
`

export default function SplitDetail ({
  destruction$,
  id,
}: {
  destruction$: Observable<any>,
  id: string,
}) {

  const split$ = getSplit(id).pipe(share())

  const datagridColumns$ = getDatagrid(id)
  .pipe(
    map(datagrid => datagrid.columnStyle.map((columStyle, index) => ({ name: columStyle.name, hidden: columStyle.hidden || false, index })))
  )

  const left$ = of(
    <a id={`left-selector-${id}`} class='btn' style='pointer-events: stroke; width: 150px;' href='#' data-target={`left-${id}`}>Left</a>
  ).pipe(share())

  const leftOptions$ = datagridColumns$.pipe(
    map(columns => (
      <div>{columns.map(column => {
        if (column.hidden) {
          return <li class="divider" tabindex="-1"></li>
        } else {
          return <li><a href="#!">{column.name}</a></li>
        }
      })}
      </div>
    )),
    share()
  )

  const right$ = of(
    <a id={`right-selector-${id}`} class='btn' style='pointer-events: stroke; width: 150px;' href='#' data-target={`right-${id}`}>Right</a>
  ).pipe(share())

  const rightOptions$ = datagridColumns$.pipe(
    map(columns => (
      <div>{columns.map(column => {
        if (column.hidden) {
          return <li class="divider" tabindex="-1"></li>
        } else {
          return <li><a href="#!">{column.name}</a></li>
        }
      })}
      </div>
    )),
    share()
  )

  const operator$ = of(ComparisonOperatorDescription["=="]).pipe(
    map(operator => <span>{operator}</span>)
  )

  const operatorComponent$ = of(<a id={`operator-selector-${id}`} class='btn' style='pointer-events: stroke; width: 220px;' href='#' data-target={`operator-${id}`}>
    <span single$={operator$} />
  </a>).pipe(share())

  const operatorOptions$ = of(
    <ul id={`operator-${id}`} class='dropdown-content'>
      {Object.values(ComparisonOperatorDescription).map(operator => <li><a href="#!">{operator}</a></li>)}
    </ul>
  )

  const splitComponent$ = split$
  .pipe(
    map(split => {
      return (
        <div>
          {split.branches.map(branch => {
            return (
              <div
                class={css`
                  display: flex;
                  flex-direction: row;
                `}
              >
                {/* <div>{datagridColumns[branch.leftIndex]}</div>
                <div>{ComparisonOperatorDescription[branch.comparison]}</div>
                <div>{datagridColumns[branch.rightIndex]}</div> */}
              </div>
            )
          })}
        </div>
      )
    }),
    share(),
    takeUntil(destruction$)
  )

  left$
  .pipe(
    withAnimationFrame,
    takeUntil(destruction$)
  )
  .subscribe(dropdown => {
    M.Dropdown.init(dropdown)
  })
  
  right$
  .pipe(
    withAnimationFrame,
    takeUntil(destruction$)
  )
  .subscribe(dropdown => {
    M.Dropdown.init(dropdown)
  })
  
  operatorComponent$
  .pipe(
    withAnimationFrame,
    takeUntil(destruction$)
  )
  .subscribe(dropdown => {
    M.Dropdown.init(dropdown)
  })

  const container$ = of(
    <div class={`${activeDetailClass} ${css`
      background-color: white;
      caret-color: auto;
      display: flex;
      flex-direction: column;
      white-space: nowrap;
    `}`}>
    <div single$={splitComponent$} />
      <p>
        Add new branch
      </p>
      <div
        class={css`
          display: flex;
          flex-direction: row;
        `}
      >
        <div single$={left$} />
        <ul id={`left-${id}`} single$={leftOptions$} class='dropdown-content'></ul>
        <div single$={operatorComponent$} />
        <div single$={operatorOptions$} />
        <div single$={right$} />
        <ul id={`right-${id}`} single$={rightOptions$} class='dropdown-content'></ul>
      </div>
    </div>
  )

  activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    withLatestFrom(container$),
    distinctUntilChanged(),
    takeUntil(destruction$)
  )
  .subscribe(([isActive, container]) => {
    classSync(container, activeDetailClass, isActive)
  })

  const tool$ = activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    startWith(true),
    distinctUntilChanged(),
    map(active => {
      if (!active) return <div />
      return (
        <div class={overlayToolsClass}>
          <div class={toolItem} onclick={() => {
            removeSplit({ id })
            setActiveToolPanel(undefined)
          }}>
            <i class="material-icons dp48">delete</i>
          </div>
        </div>
      )
    }),
    takeUntil(destruction$)
  )

  // get all flows, make it flow, save new datagrid (stripped of data)
  merge(flowEntity$, datagridEntity$.pipe(filter(i => i.id !== id)))
  .pipe(
    startWith(1),
    switchMap(() => from(getAllFlowsArray())),
    withIndexedDb(),
    switchMap(([flows, db]) => makeItFlow(db, flows, id)),
    map(value => {
      if (typeof value === 'string') {
        newDatagrid({ id })
      } else {
        value.dataset = []
        newDatagrid(value)
      }
    }),
    takeUntil(destruction$)
  )
  .subscribe()

  return (
    <div class={detailClass}
      onmousedown={() => {
        setActiveToolPanel({ id, type: Tool.split, collapse: () => removeOverlayDetail({ id }) })
      }}>
      <div class={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}>
        <div>
          <div single$={tool$} />
          <span>Decision</span>
        </div>
        <div style='cursor: pointer;' onclick={() => {
          setActiveToolPanel(undefined)
          removeOverlayDetail({ id })
        }}><i class="material-icons dp48">close</i></div>
      </div>
      <div single$={container$} />
    </div>
  )
}
