import { concatMap, filter, from, merge, Observable, switchMap } from "rxjs";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { Database, databaseEntity$ } from "./event";
import { DatabaseEvent } from "./command";

export function getAllDatabases(): Observable<Database> {
  return indexedDB$
  .pipe(
    concatMap(db => db.query(IndexedDBEntity.database)),
    switchMap(values => from(values as Database[])),
  )
}

export function getDatabase(id): Observable<Database & {
  meta: {
      entity: IndexedDBEntity;
      eventType: DatabaseEvent;
  };
}> {
  return merge(
    indexedDB$.pipe(concatMap(db => db.get(IndexedDBEntity.database, id))),
    databaseEntity$.pipe(filter(i => i.id === id))
  ).pipe(filter(i => !!i))
}
