import d3 = require("d3")
import { Shape } from "../event"

export function eastTrianglePath (path: d3.Selection<SVGPathElement, unknown, null, undefined>, shape: Shape) {
  return path
    .attr("id", `${shape.id}`)
    .attr("d", 'M -50,50 -50,-50 50,0')
    .attr("stroke", shape.color || '#e993ff')
    .attr("fill", shape.color || '#e993ff')
    .attr("transform", `translate(${shape.x}, ${shape.y})`)
}
