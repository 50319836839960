import { EntityEventHandlers, entityServiceFactory, SideEventHandlers } from "@taterer/rx-entity"
import { displayOverlayDetailCommands$, DisplayOverlayDetailEvent, NewOverlayDetail, removeOverlayDetail, RemoveOverlayDetail } from "./command"
import { memoryDB$, MemoryEntity } from '../../persistence/memoryDB'
import { Tool } from "../toolSettings"
import { setActiveToolPanel } from "../toolSettings/command"

export interface DisplayOverlayDetail {
  id: string
  type: Tool
  show: boolean
}

export const displayOverlayDetailEventHandlers: EntityEventHandlers<DisplayOverlayDetail, DisplayOverlayDetailEvent> = {
  [DisplayOverlayDetailEvent.new]: (_entity, event: NewOverlayDetail) => {
    return {
      id: event.id,
      type: event.type,
      show: true
    }
  },
  [DisplayOverlayDetailEvent.remove]: (entity, event: RemoveOverlayDetail) => {
    return {
      ...entity,
      show: false,
      deleted: true
    }
  },
}

export const displayOverlayDetailSideHandlers: SideEventHandlers<DisplayOverlayDetail, DisplayOverlayDetailEvent> = {
  [DisplayOverlayDetailEvent.new]: (_, event: NewOverlayDetail, updatedEntity) => {
    setActiveToolPanel({ id: event.id, type: event.type, collapse: () => removeOverlayDetail({ id: event.id }) })
    return updatedEntity
  }
}

export const displayOverlayDetailEntity$ = displayOverlayDetailCommands$
  .pipe(
    entityServiceFactory(
      memoryDB$,
      MemoryEntity.displayOverlayDetail,
      displayOverlayDetailEventHandlers,
      displayOverlayDetailSideHandlers
    ),
  )
