import d3 = require("d3")
import { Shape } from "../event"

export function circlePath (path: d3.Selection<SVGPathElement, unknown, null, undefined>, shape: Shape) {
  return path
    .attr("id", `${shape.id}`)
    .attr("d", `M -50, 0
    a 50,50 0 1,1 100,0
    a 50,50 0 1,1 -100,0`)
    .attr("stroke", shape.color || '#ff7a7a')
    .attr("fill", shape.color || '#ff7a7a')
    .attr("transform", `translate(${shape.x}, ${shape.y})`)
}
