import { css } from "@emotion/css"
import {
  distinctUntilChanged,
  map,
  Observable,
  of,
  share,
  startWith,
  takeUntil,
  withLatestFrom
} from "rxjs"
import { Tool } from "../../../domain/toolSettings"
import { setActiveToolPanel } from "../../../domain/toolSettings/command"
import { activeToolPanel$ } from "../../../domain/toolSettings/event"
import { classSync } from "@taterer/rx-jsx"
import Datagrid from "../Datagrid"
import { removeOverlayDetail } from "../../../domain/displayOverlayDetail/command"
import { overlayToolsClass, toolItem } from "../../styles"
import { removeDatabase } from "../../../domain/database/command"
import { addRow, addColumn } from "../../../domain/datagrid/command"

const detailClass = css`
  background-color: #add8e6ee;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
`

const activeDetailClass = css`
  background-color: #eafaff !important;
`

export default function DatabaseDetail ({ destruction$, id }: { destruction$: Observable<any>, id: string }) {
  const datagrid$ = of(
    <div
      class={activeDetailClass}
      style='background-color: white;'
      onmousedown={event => {
        setActiveToolPanel({ id, type: Tool.database, collapse: () => removeOverlayDetail({ id }) })
        event.stopPropagation()
      }}>
      <Datagrid id={id}
        destruction$={destruction$}
        // headerStyle={}
        />
    </div>
  ).pipe(share())

  activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    withLatestFrom(datagrid$),
    distinctUntilChanged(),
    takeUntil(destruction$)
  )
  .subscribe(([isActive, datagrid]) => {
    classSync(datagrid, activeDetailClass, isActive)
  })

  const tool$ = activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    startWith(true),
    distinctUntilChanged(),
    map(active => {
      if (!active) return <div />
      return (
        <div class={overlayToolsClass}>
          <div class={toolItem} onclick={() => addRow({ id })}>+Row</div>
          {/* <div class={toolItem} onclick={() => removeRow({ id })}>Delete Row</div> */}
          <div class={toolItem} onclick={() => addColumn({ id, title: 'New Column' })}>+Column</div>
          {/* <div class={toolItem}>Delete Column</div> */}
          <div class={toolItem} onclick={() => {
            removeDatabase({ id })
            setActiveToolPanel(undefined)
          }}>
            <i class="material-icons dp48">delete</i>
          </div>
        </div>
      )
    }),
    takeUntil(destruction$)
  )
  
  return (
    <div class={detailClass}
      onmousedown={() => {
        setActiveToolPanel({ id, type: Tool.database, collapse: () => removeOverlayDetail({ id }) })
      }}>
      <div class={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}>
        <div>
          <div single$={tool$} />
          <span>Data</span>
        </div>
        <div style='cursor: pointer;' onclick={() => {
          setActiveToolPanel(undefined)
          removeOverlayDetail({ id })
        }}><i class="material-icons dp48">close</i></div>
      </div>
      <div single$={datagrid$} />
    </div>
  )
}
