import { Observable } from 'rxjs'
import { Tool } from '.'
import { makeTooltipsVisible$, setActiveTool$, setActiveToolPanel$ } from './command'

export const activeTool$ = setActiveTool$

export const tooltipsVisible$ = makeTooltipsVisible$

type ToolPanel = {
  id: string,
  type: Tool,
  collapse: Function,
} | undefined

export const activeToolPanel$: Observable<ToolPanel> = setActiveToolPanel$
