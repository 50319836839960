import { map, Observable, share } from 'rxjs'
import { css, cx } from '@emotion/css'
import DataCell from './Cell'
import { RowStyle, ColumnStyle } from '../../../domain/datagrid'
import { Datagrid } from '../../../domain/datagrid/event'

export default function DataRow ({ destruction$, datagridById$, datagridId, rowIndex, row, rowStyle, columnStyle }: {
  destruction$: Observable<any>,
  datagridById$: Observable<Datagrid>
  datagridId: string,
  rowIndex: number,
  row: string[],
  rowStyle?: RowStyle,
  columnStyle?: ColumnStyle[]
}) {
  if (rowStyle?.hidden) {
    return <div />
  }
  const datagridRowByIndex$ = datagridById$
  .pipe(
    map(datagrid => {
      if (rowIndex < 0) {
        return datagrid.columnStyle.map(style => style.name)
      }
      return datagrid.dataset[rowIndex]
    }),
    share(),
  )

  return (
    <div class={cx(rowStyle?.class, css`
      display: flex;
    `)} style={rowStyle?.height ? `height: ${rowStyle.height}px;` : ''}>
      {row.map((cell, index) => <DataCell
        destruction$={destruction$}
        datagridId={datagridId}
        datagridRowByIndex$={datagridRowByIndex$}
        rowIndex={rowIndex}
        columnIndex={index}
        cell={cell}
        columnStyle={columnStyle[index]}
        />)}
    </div>
  )
}
