import { css } from "@emotion/css";
import { withAnimationFrame } from "@taterer/rx-jsx";
import { takeUntil, Observable, map, filter, merge, share, withLatestFrom, startWith, switchMap, debounceTime } from "rxjs";
import { shapeEntity$ } from "../../../domain/shape/event";
import { displayOverlayDetailEntity$ } from "../../../domain/displayOverlayDetail/event";
import { ShapeType } from "../../../domain/shape/command";
import { Shape } from "../../../domain/shape/event";
import { dragElement } from "../../utils/dragWindow";
import CalculationDetail from "./CalculationDetail";
import DatabaseDetail from "./DatabaseDetail";
import ViewDetail from "./ViewDetail";
import SplitDetail from "./SplitDetail";
import AggregationDetail from "./AggregationDetail";
import { getDatagrid } from "../../../domain/datagrid/query";
import { Datagrid } from "../../../domain/datagrid/event";

export default function OverlayDetail ({
  destruction$,
  entityId,
  shape,
  shape$
}: {
  destruction$: Observable<any>,
  entityId: string,
  shape: Shape
  shape$: Observable<{ type: ShapeType; x: number; y: number; }>
}) {
  const overlayDetail$ = displayOverlayDetailEntity$
  .pipe(
    filter(overlayDetail => overlayDetail.id === entityId),
  )

  const deletedShape$ = shapeEntity$
  .pipe(
    filter(i => i.deleted && i.id === shape.id)
  )

  const overlayDetailDestruction$ = merge(destruction$, deletedShape$)
  const overlayDetailChange$ = merge(overlayDetail$, destruction$, deletedShape$)

  const mount$ = merge(
    overlayDetail$,
    shape$,
  )
  .pipe(
    withLatestFrom(
      overlayDetail$,
      shape$.pipe(
        startWith(shape)
      ),
      getDatagrid(entityId).pipe(
        startWith(undefined)
      )
    ),
    map(([_, displayOverlayDetail, latestShape, datagrid]) => {
      if (!displayOverlayDetail.show) {
        return
      }

      return (
        <div
          id={`overlay-detail-${shape.id}`}
          style={`
            position: absolute;
            ${getWindowCenterGivenConstraints(
              getDatagridWidth(datagrid),
              getDatagridHeight(datagrid),
              latestShape.x,
              latestShape.y,
              window.innerWidth,
              window.innerHeight
            )}
          `}>
          <div
            class={css`
              pointer-events: stroke;
              caret-color: transparent;
              display: flex;
              top: -50%;
              left: -50%;
              align-items: center;
              justify-content: center;
              width: 100%;
              height: 100%;
              position: absolute;
            `}>
            <div>
              {
                shape.type === ShapeType.square ? <DatabaseDetail id={entityId} destruction$={overlayDetailChange$} /> :
                shape.type === ShapeType.hexPara ? <CalculationDetail id={entityId} destruction$={overlayDetailChange$} /> :
                shape.type === ShapeType.diamond ? <SplitDetail id={entityId} destruction$={overlayDetailChange$} /> :
                shape.type === ShapeType.eastTriangle ? <AggregationDetail id={entityId} destruction$={overlayDetailChange$} /> :
                shape.type === ShapeType.circle ? <ViewDetail id={entityId} destruction$={overlayDetailChange$} /> :
                null
              }
            </div>
          </div>
        </div>
      )
    }),
    share(),
    takeUntil(overlayDetailDestruction$)
  )

  mount$
  .pipe(
    filter(i => !!i),
    withAnimationFrame,
    takeUntil(destruction$)
  )
  .subscribe(element => dragElement(element))

  return <div single$={mount$} />
}

const datagridColumnWidth = 200
const overlayMarginWidth = 40

function getDatagridWidth (datagrid?: Datagrid) {
  if (!datagrid) {
    return 0
  }
  const calculatedWidth = datagrid.columnStyle.filter(i => !i.hidden).length * datagridColumnWidth + overlayMarginWidth
  const maxWidth = window.innerWidth + overlayMarginWidth
  if (calculatedWidth > maxWidth) {
    return maxWidth
  } else {
    return calculatedWidth
  }
}

const datagridRowHeight = 50.75
const overlayMarginHeight = 120

function getDatagridHeight (datagrid?: Datagrid) {
  if (!datagrid) {
    return 0
  }
  const calculatedHeight = datagrid.dataset.length * datagridRowHeight + datagridRowHeight + overlayMarginHeight
  const maxHeight = window.innerHeight + overlayMarginHeight
  if (calculatedHeight > maxHeight) {
    return maxHeight
  } else {
    return calculatedHeight
  }
}

// Make sure the overlay isn't outside of the visibile window
function getWindowCenterGivenConstraints(width, height, startX, startY, constraintX, constraintY) {
  let right
  let left
  let bottom
  let top

  const constrainedHeight = height > constraintY ? constraintY : height

  // over the right edge
  if (startX + width / 2 > constraintX) {
    right = width / 2

  } else {
    // over the left edge
    if (startX - width / 2 < 0) {
      left = width / 2
    }
  }

  // over the top
  if (startY - constrainedHeight / 2 < 0) {
    top = constrainedHeight / 2 + 20
  } else {
    // below the bottom
    if (startY + constrainedHeight / 2 > constraintY) {
      top = constrainedHeight / 2 + 20
      // if it can be contained
      if (constrainedHeight < constraintY) {
        bottom = constraintY - constrainedHeight / 2 + 40
      }
    }
  }

  return `
    ${right !== undefined ? `right: ${right}` : `left: ${left !== undefined ? left : startX}`}px;
    ${bottom !== undefined ? `top: ${bottom}` : `top: ${top !== undefined ? top : startY}`}px;
  `
}
