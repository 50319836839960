import { css } from "@emotion/css"
import { Tool } from "../../../domain/toolSettings"

const tooltipClass = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const toolTooltip = {
  [Tool.pointer]: <div class={tooltipClass}>
      <div>
        Select and move elements
      </div>
    </div>,
  [Tool.database]: <div class={tooltipClass}>
      <div>
        Data Table
      </div>
    </div>,
  [Tool.calculation]: <div class={tooltipClass}>
      <div>
        Computation
      </div>
    </div>,
  [Tool.split]: <div class={tooltipClass}>
      <div>
        Decision
      </div>
    </div>,
  [Tool.aggregation]: <div class={tooltipClass}>
      <div>
        Merge
      </div>
    </div>,
  [Tool.view]: <div class={tooltipClass}>
      <div>
        Visualization
      </div>
    </div>,
}

export default function Tooltip ({ tool }) {
  return (
    <div class={css`
      left: 78px;
      padding: 15px;
      position: absolute;
      display: none;
      background: #eee;
      white-space: nowrap;
    `}>
      <div>{toolTooltip[tool]}</div>
    </div>
  )
}
