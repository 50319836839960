import { FlowSource } from "../../../domain/flow/command"

export function parseTransferData (event): FlowSource {
  const raw = JSON.parse(event.dataTransfer.getData('text'))
  return {
    sourceId: raw.sourceId,
    sourceIndex: raw.sourceIndex,
    sourceTitle: raw.sourceTitle,
    sourceEntity: raw.sourceEntity,
  } 
}
