import { merge } from "rxjs"
import { eventFactory } from "@taterer/rx-entity"
import { MemoryEntity } from "../../persistence/memoryDB"

export enum LineEvent {
  new = 'new',
  remove = 'remove',
}

export enum LineType {
  basic = "basic",
}

export interface NewLine {
  id: string
  sourceId: string
  sourceElementId: string
  sourceShapeId: string
  destinationId: string
  destinationElementId: string
  destinationShapeId
  type?: LineType
  color?: string
  size?: number
}

export const [newLine$, newLine] = eventFactory<NewLine>({ entity: MemoryEntity.line, eventType: LineEvent.new })

export interface RemoveLine {
  id: string
}

export const [removeLine$, removeLine] = eventFactory<RemoveLine>({ entity: MemoryEntity.line, eventType: LineEvent.remove })

export const lineCommands$ = merge(
  newLine$,
  removeLine$,
)
