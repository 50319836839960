import { Persistence } from "@taterer/persist"
import { map, Observable, of, withLatestFrom } from "rxjs"
import { IndexedDBEntity } from "../../persistence/indexedDB"
import { MemoryEntity } from "../../persistence/memoryDB"
import { Calculation } from "../calculation/event"
import { getCalculation } from "../calculation/query"
import { Datagrid } from "../datagrid/event"
import { getDatagrid } from "../datagrid/query"
import { getVariables, solveEquation } from "../mathite"
import { Flow } from "./event"

export function makeItFlow (db: Persistence<any, MemoryEntity | IndexedDBEntity>, flows: Flow[], destinationId: string): Observable<Datagrid | string> {
  // should merge all source datagrids into a single datagrid
  // if there are multiple datagrids, each row in each datagrid should be added for every row in other tables (matrix multiplication)
  const destinationFlows = flows.filter(i => i.destinationId === destinationId)
  const sourceObservables: Observable<Datagrid | string>[] = []
  const sourceIndexMap = {}

  if (!destinationFlows.length) {
    return of('Error')
  }

  destinationFlows.reduce((acc, destinationFlow) => {
    if (!acc.includes(destinationFlow.sourceId)) {
      acc.push(destinationFlow.sourceId)
      if (destinationFlow.sourceEntity === IndexedDBEntity.database) {
        sourceObservables.push(getDatagrid(destinationFlow.sourceId, of(db)))
      } else {
        sourceObservables.push(makeItFlow(db, flows, destinationFlow.sourceId))
      }
    }
    return acc
  }, [])

  const obs = sourceObservables.reduce((accumulatedDatagrid, observableDatagrid) => {
    if (!accumulatedDatagrid) {
      return observableDatagrid
      .pipe(
        map(datagridNomad => {
          if (datagridNomad === 'Error') {
            return 'Error'
          }
          const datagrid = datagridNomad as Datagrid
          sourceIndexMap[datagrid.id] = 0

          return {
            id: destinationId,
            columnStyle: [...datagrid.columnStyle],
            dataset: [...datagrid.dataset],
            height: 0,
            width: 0,
            rowStyle: [],
          }
        })
      )
    }
    return accumulatedDatagrid
    .pipe(
      withLatestFrom(observableDatagrid),
      map(([accumulatedNomad, datagridNomad]) => {
        if (accumulatedNomad === 'Error' || datagridNomad === 'Error') {
          return 'Error'
        }
        const accumulated = accumulatedNomad as Datagrid
        const datagrid = datagridNomad as Datagrid

        const dataset: string[][] = []
        accumulated.dataset.forEach(accrow => datagrid.dataset.forEach(row => dataset.push([...accrow, ...row])))
        sourceIndexMap[datagrid.id] = accumulated.columnStyle.length

        const newAccumulated: Datagrid = {
          id: destinationId,
          columnStyle: [...accumulated.columnStyle, ...datagrid.columnStyle],
          dataset,
          height: 0,
          width: 0,
          rowStyle: [],
        }

        return newAccumulated
      })
    )
  }, undefined)

  if (destinationFlows[0].destinationEntity === IndexedDBEntity.calculation) {
    return obs
    .pipe(
      withLatestFrom(getCalculation(destinationId, of(db))),
      map(([datagridNomad, calculation]: [Datagrid | string, Calculation]) => {
        if (datagridNomad === 'Error') {
          return 'Error'
        }
        const datagrid = datagridNomad as Datagrid
        const baseVariables: { name: string, value?: number }[] = getVariables(calculation.equation).map(i => ({ name: i }))
        datagrid.columnStyle.push({ name: calculation.output })
        datagrid.dataset = datagrid.dataset.map(row => {
          const variables = [...baseVariables]
          destinationFlows.forEach(destinationFlow => {
            try {
              variables[destinationFlow.destinationIndex].value = parseFloat(row[sourceIndexMap[destinationFlow.sourceId] + destinationFlow.sourceIndex])
            } catch (err) {
              throw new Error(`Failed to parse float for ${destinationFlow.sourceTitle}: ${err.message}`)
            }
          })
          return [ ...row, solveEquation(calculation.equation, variables as { name: string, value: number }[]).solution.toString() ]
        })
        return datagrid
      })
    )
  } else {
    return obs
  }
}
