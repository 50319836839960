import d3 = require("d3")
import { Shape } from "../event"

export function diamondPath (path: d3.Selection<SVGPathElement, unknown, null, undefined>, shape: Shape) {
  return path
    .attr("id", `${shape.id}`)
    .attr("d", 'M -50,0 0,-50 50,0 0,50')
    .attr("stroke", shape.color || '#ffba54')
    .attr("fill", shape.color || '#ffba54')
    .attr("transform", `translate(${shape.x}, ${shape.y})`)
}
