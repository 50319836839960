import { v4 as uuid } from 'uuid'
import { Observable, shareReplay } from "rxjs";
import {
  SideEventHandlers,
  EntityEventHandlers,
  entityServiceFactory,
  subscriptionFactory
} from "@taterer/rx-entity";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { newShape, removeShape } from '../shape/command';
import {
  NewCalculation,
  calculationCommands$,
  CalculationEvent,
  FormulateCalculation,
  MoveCalculation,
  RemoveCalculation,
  RenameCalculation
} from './command';
import { generateShapeId, toolToShapeMap } from '../shape';

export interface Calculation {
  id: string
  deleted?: boolean
  created_at: number
  x: number
  y: number
  title?: string
  equation: string
  notes?: string
  output?: string
}

const calculationEventHandlers: EntityEventHandlers<Calculation, CalculationEvent> = {
  [CalculationEvent.new]: (_, event: NewCalculation) => {
    return {
      id: event.id || uuid(),
      created_at: Date.now(),
      equation: 'm*x+b',
      output: 'y',
      ...event,
    }
  },
  [CalculationEvent.remove]: (entity, event: RemoveCalculation) => {
    return {
      ...entity,
      deleted: true
    }
  },
  [CalculationEvent.rename]: (entity, event: RenameCalculation) => {
    return {
      ...entity,
      title: event.title
    }
  },
  [CalculationEvent.move]: (entity, event: MoveCalculation) => {
    return {
      ...entity,
      x: event.x || entity.x,
      y: event.y || entity.y,
    }
  },
  [CalculationEvent.formulate]: (entity, event: FormulateCalculation) => {
    return {
      ...entity,
      equation: event.equation,
      output: event.output,
      notes: event.notes || entity.notes,
    }
  },
}

const calculationSideEventHandlers: SideEventHandlers<Calculation, CalculationEvent> = {
  [CalculationEvent.new]: (_, event: NewCalculation, updatedEntity): Calculation => {
    newShape({
      id: generateShapeId(updatedEntity.id, toolToShapeMap.calculation),
      x: event.x,
      y: event.y,
      type: toolToShapeMap.calculation,
    })
    return updatedEntity
  },
  [CalculationEvent.remove]: (_, event, updatedEntity) => {
    removeShape({ id: generateShapeId(event.id, toolToShapeMap.calculation) })
    return updatedEntity
  },
}

export const calculationEntity$ = calculationCommands$
  .pipe(
    entityServiceFactory<Calculation, any>(
      indexedDB$,
      IndexedDBEntity.calculation,
      calculationEventHandlers,
      calculationSideEventHandlers
    ),
    shareReplay(1)
  )

export function subscribeToCalculationServices (destruction$: Observable<any>) {
  subscriptionFactory([calculationEntity$], destruction$)
}
