import { merge } from "rxjs"
import { eventFactory } from "@taterer/rx-entity"
import { IndexedDBEntity } from "../../persistence/indexedDB"

export enum ViewEvent {
  new = 'new',
  remove = 'remove',
  rename = 'rename',
  move = 'move',
}

export interface NewView {
  id?: string,
  title?: string,
  x: number,
  y: number,
}

export const [newView$, newView] = eventFactory<NewView>({ entity: IndexedDBEntity.view, eventType: ViewEvent.new })

export interface RemoveView {
  id: string
}

export const [removeView$, removeView] = eventFactory<RemoveView>({ entity: IndexedDBEntity.view, eventType: ViewEvent.remove })

export interface RenameView {
  id: string
  title: string
}

export const [renameView$, renameView] = eventFactory<RenameView>({ entity: IndexedDBEntity.view, eventType: ViewEvent.rename })

export interface MoveView {
  id: string
  x: number
  y: number
}

export const [moveView$, moveView] = eventFactory<MoveView>({ entity: IndexedDBEntity.view, eventType: ViewEvent.move })

export const viewCommands$ = merge(
  newView$,
  removeView$,
  renameView$,
  moveView$,
)
