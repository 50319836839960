import { v4 as uuid } from 'uuid'
import { Observable, shareReplay } from "rxjs";
import {
  SideEventHandlers,
  EntityEventHandlers,
  entityServiceFactory,
  subscriptionFactory
} from "@taterer/rx-entity";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { newShape, removeShape } from '../shape/command';
import {
  NewView,
  viewCommands$,
  ViewEvent,
  MoveView,
  RemoveView,
  RenameView
} from './command';
import { generateShapeId, toolToShapeMap } from '../shape';
import { newDatagrid, removeDatagrid } from '../datagrid/command';

export interface View {
  id: string
  deleted?: boolean
  created_at: number
  x: number
  y: number
  title?: string
}

const viewEventHandlers: EntityEventHandlers<View, ViewEvent> = {
  [ViewEvent.new]: (_, event: NewView) => {
    return {
      id: event.id || uuid(),
      created_at: Date.now(),
      ...event,
    }
  },
  [ViewEvent.remove]: (entity, event: RemoveView) => {
    return {
      ...entity,
      deleted: true
    }
  },
  [ViewEvent.rename]: (entity, event: RenameView) => {
    return {
      ...entity,
      title: event.title
    }
  },
  [ViewEvent.move]: (entity, event: MoveView) => {
    return {
      ...entity,
      x: event.x || entity.x,
      y: event.y || entity.y,
    }
  },
}

const viewSideEventHandlers: SideEventHandlers<View, ViewEvent> = {
  [ViewEvent.new]: (_, event: NewView, updatedEntity): View => {
    newDatagrid({ id: updatedEntity.id, dataset: [], columnStyle: [] })
    newShape({
      id: generateShapeId(updatedEntity.id, toolToShapeMap.view),
      x: event.x,
      y: event.y,
      type: toolToShapeMap.view,
    })
    return updatedEntity
  },
  [ViewEvent.remove]: (_, event, updatedEntity) => {
    removeDatagrid({ id: event.id })
    removeShape({ id: generateShapeId(event.id, toolToShapeMap.view) })
    return updatedEntity
  },
}

export const viewEntity$ = viewCommands$
  .pipe(
    entityServiceFactory<View, any>(
      indexedDB$,
      IndexedDBEntity.view,
      viewEventHandlers,
      viewSideEventHandlers
    ),
    shareReplay(1)
  )

export function subscribeToViewServices (destruction$: Observable<any>) {
  subscriptionFactory([viewEntity$], destruction$)
}
