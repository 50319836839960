import { css } from "@emotion/css";
import { Observable } from "rxjs";
import { ShapeType } from "../../../domain/shape/command";
import { Shape } from "../../../domain/shape/event";
import DatabaseOutput from "./DatabaseOutput";
import CalculationOutput from "./CalculationOutput";
import SplitOutput from "./SplitOutput";

export default function Output ({ destruction$, shape }: { destruction$: Observable<any>, shape: Shape }) {
  return (
    <div class={css`
      left: 110px;
      position: absolute;
      /* pointer-events: stroke; */
      margin: 20px;
    `}>
      {
        shape.type === ShapeType.square ? <DatabaseOutput destruction$={destruction$} shape={shape} /> :
        shape.type === ShapeType.hexPara ? <CalculationOutput destruction$={destruction$} shape={shape} /> :
        shape.type === ShapeType.circle ? <div /> :
        shape.type === ShapeType.diamond ? <SplitOutput destruction$={destruction$} shape={shape} /> :
        <div>Out</div>
      }
    </div>
  )
}
