import { classSync } from '@taterer/rx-jsx';
import { Observable, of, takeUntil, withLatestFrom } from 'rxjs';
import { RouteRegExp } from '../../../domain/route';
import Link from '../Link';
import { firstPathChange$ } from '../Router/event';

export default function NavbarItem ({
  destruction$,
  style,
  title,
  path
}: {
  destruction$: Observable<any>,
  style?: string,
  title,
  path
}) {
  const navbarItem$ = of(
    <li>
      <Link style={style} path={path} title={title} />
    </li>
  )

  firstPathChange$
  .pipe(
    withLatestFrom(navbarItem$),
    takeUntil(destruction$)
  )
  .subscribe(([firstPath, navbarItem]) => {
    classSync(
      navbarItem,
      'active',
      (RouteRegExp[path] && RouteRegExp[path].test(firstPath)) ||
        // If there is no match for any route regular expressions, set active if the given navbar path item has no corresponding route regular expression
        (!RouteRegExp[path] && !Object.values(RouteRegExp).some(regexp => regexp.test(firstPath)))
    )
  })

  return (
    <div single$={navbarItem$} />
  )
}
