import { css } from "@emotion/css";
import { Observable } from "rxjs";
import { ShapeType } from "../../../domain/shape/command";
import { Shape } from "../../../domain/shape/event";
import CalculationInput from "./CalculationInput";
import SplitInput from "./SplitInput";
import ViewInput from "./ViewInput";
import AggregationInput from "./AggregationInput";

export default function Input ({ destruction$, shape }: { destruction$: Observable<any>, shape: Shape }) {
  return (
    <div class={css`
      right: 110px;
      position: absolute;
      text-align: right;
      /* pointer-events: stroke; */
      margin: 20px;
    `}>
      {
        shape.type === ShapeType.square ? <div /> :
        shape.type === ShapeType.hexPara ? <CalculationInput destruction$={destruction$} shape={shape} /> :
        shape.type === ShapeType.circle ? <ViewInput destruction$={destruction$} shape={shape} /> :
        shape.type === ShapeType.diamond ? <SplitInput destruction$={destruction$} shape={shape} /> :
        shape.type === ShapeType.eastTriangle ? <AggregationInput destruction$={destruction$} shape={shape} /> :
        <div>In</div>
      }
    </div>
  )
}
