import d3 = require("d3")
import { Shape } from "../event"

export function hexParaPath (path: d3.Selection<SVGPathElement, unknown, null, undefined>, shape: Shape) {
  return path
    .attr("id", `${shape.id}`)
    .attr("d", 'M -50,50 -30,0 -50,-50 30,-50 50,0 30,50')
    .attr("stroke", shape.color || 'lightgreen')
    .attr("fill", shape.color || 'lightgreen')
    .attr("transform", `translate(${shape.x}, ${shape.y})`)
}
