import { evaluate } from "mathjs"
import ignoreList from "./ignoreList"

export const variableRegex = /([A-Za-z]([A-Za-z0-9 _]*[A-Za-z0-9])*)/g

// parses the text and returns identified variables
export function getVariables (text: string): Array<string> {
  if (!text) return []
  const matches = text.match(variableRegex)
  if (!matches) return []
  const uniqueMatches = matches.reduce((prev, curr) => {
    if (!prev.includes(curr)) {
      return [...prev, curr]
    } else {
      return prev
    }
  }, [])

  return uniqueMatches.reduce((prev, curr) => {
    if (!ignoreList.includes(curr)) {
      return [...prev, curr]
    } else {
      return prev
    }
  }, [])
}

// replaces equation text with actual values and evaluates the result
export function solveEquation (text: string, variables?: { name: string, value: number }[]): { solution?: string } {
  let mathToParse = text
  const sortedVariables = variables.sort((a, b) => b.name.length - a.name.length)

  for (const variable of sortedVariables) {
    mathToParse = mathToParse.replace(new RegExp(variable.name, 'g'), `(${variable.value})`)
  }

  let solution
  try {
    solution = evaluate(mathToParse)
  } catch (error) {
    console.log('Error evaluating', error)
  }

  return {
    solution
  }
}
