import { css } from "@emotion/css";
import { Tool } from "../../../domain/toolSettings";
import ControlPanelTool from "./ControlPanelItem";

export default function ControlPanel ({ destruction$ }) {
  return (
    <div class={css`
      position: fixed;
      display: flex;
      flex-direction: column;
      left: 0px;
      height: 80vh;
      pointer-events: none;
      justify-content: center;
    `}>
      <ControlPanelTool
        destruction$={destruction$}
        tool={Tool.pointer}
        />
      <ControlPanelTool
        destruction$={destruction$}
        tool={Tool.database}
        />
      <ControlPanelTool
        destruction$={destruction$}
        tool={Tool.calculation}
        />
      <ControlPanelTool
        destruction$={destruction$}
        tool={Tool.split}
        />
      <ControlPanelTool
        destruction$={destruction$}
        tool={Tool.aggregation}
        />
      <ControlPanelTool
        destruction$={destruction$}
        tool={Tool.view}
        />
    </div>
  )
}
