import { EntityEventHandlers, entityServiceFactory } from '@taterer/rx-entity'
import { memoryDB$, MemoryEntity } from '../../persistence/memoryDB'
import { NewLine, RemoveLine, lineCommands$, LineEvent, LineType } from './command'

export interface Line {
  id: string
  sourceId: string
  sourceElementId: string
  sourceShapeId: string
  destinationId: string
  destinationElementId: string
  destinationShapeId
  type?: LineType
  color?: string
  size?: number
}

export const lineEventHandlers: EntityEventHandlers<Line, LineEvent> = {
  [LineEvent.new]: (_entity, event: NewLine) => {
    return {
      id: event.id,
      sourceId: event.sourceId,
      sourceElementId: event.sourceElementId,
      sourceShapeId: event.sourceShapeId,
      destinationId: event.destinationId,
      destinationElementId: event.destinationElementId,
      destinationShapeId: event.destinationShapeId,
      type: event.type,
      color: event.color,
      size: event.size,
    }
  },
  [LineEvent.remove]: (entity, event: RemoveLine) => {
    return {
      ...entity,
      deleted: true
    }
  },
}

export const lineEntity$ = lineCommands$
  .pipe(
    entityServiceFactory(
      memoryDB$,
      MemoryEntity.line,
      lineEventHandlers,
    ),
  )
