import { takeUntil } from "rxjs";
import { pushHistory$, replaceHistory$ } from "./command";
import { BASE_URL } from "./config";

export function subscribeToHistory (destruction$) {
  pushHistory$
  .pipe(
    takeUntil(destruction$)
  )
  .subscribe(pushHistory => {
    history.pushState({}, '', `${BASE_URL}${pushHistory.url}`)
  })
  replaceHistory$
  .pipe(
    takeUntil(destruction$)
  )
  .subscribe(replaceHistory => {
    history.replaceState({}, '', `${BASE_URL}${replaceHistory.url}`)
  })
}
