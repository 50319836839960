import { shareReplay, Subject } from "rxjs"
import { MoveShape, ShapeType } from "./command"
import { Shape } from "./event"
import { eastTrianglePath } from "./paths/eastTrianglePath"
import { hexParaPath } from "./paths/hexParaPath"
import { squarePath } from "./paths/squarePath"
import { diamondPath } from "./paths/diamondPath"
import { circlePath } from "./paths/circlePath"
import { Tool } from "../toolSettings"
import { newDatabase, moveDatabase } from "../database/command"
import { newCalculation, moveCalculation } from "../calculation/command"
import { newSplit, moveSplit } from "../split/command"
import { newAggregation, moveAggregation } from "../aggregation/command"
import { newView, moveView } from "../view/command"

export function generateShapeId (id: string, shapeType: ShapeType) {
  return `${shapeType}-${id}`
}

const mountSVGSubject$ = new Subject<d3.Selection<SVGSVGElement, unknown, null, undefined>>()

export const mountSVG$ = mountSVGSubject$.pipe(shareReplay(1))

mountSVG$.subscribe()

export const mountSVG = i => mountSVGSubject$.next(i)

export function drawShape (mount: d3.Selection<SVGSVGElement, unknown, null, undefined>, shape: Shape) {
  if (shape.type === ShapeType.diamond) {
    return diamondPath(mount.append('path'), shape)
  } else if (shape.type === ShapeType.eastTriangle) {
    return eastTrianglePath(mount.append('path'), shape)
  } else if (shape.type === ShapeType.hexPara) {
    return hexParaPath(mount.append('path'), shape)
  } else if (shape.type === ShapeType.square) {
    return squarePath(mount.append('path'), shape)
  } else if (shape.type === ShapeType.circle) {
    return circlePath(mount.append('path'), shape)
  }
}

export function newShapeAggregate (add: { x: number, y: number }, tool: Tool) {
  if (tool === Tool.database) {
    newDatabase(add)
  } else if (tool === Tool.calculation) {
    newCalculation(add)
  } else if (tool === Tool.split) {
    newSplit(add)
  } else if (tool === Tool.aggregation) {
    newAggregation(add)
  } else if (tool === Tool.view) {
    newView(add)
  }
}

export function moveShapeAggregate (move: MoveShape) {
  const [_match, shape, id] = move.id.match(/([^-]*)-(.*)/)
  const tool = shapeToToolMap[shape]
  const event = { id, x: move.x, y: move.y }
  if (tool === Tool.database) {
    moveDatabase(event)
  } else if (tool === Tool.calculation) {
    moveCalculation(event)
  } else if (tool === Tool.split) {
    moveSplit(event)
  } else if (tool === Tool.aggregation) {
    moveAggregation(event)
  } else if (tool === Tool.view) {
    moveView(event)
  } else {
    debugger
  }
}

export const shapeToToolMap: { [key in ShapeType]: Tool } = {
  diamond: Tool.split,
  circle: Tool.view,
  square: Tool.database,
  hexPara: Tool.calculation,
  eastTriangle: Tool.aggregation,
}

export const toolToShapeMap: { [key in Tool]: ShapeType } = {
  pointer: undefined,
  database: ShapeType.square,
  calculation: ShapeType.hexPara,
  split: ShapeType.diamond,
  aggregation: ShapeType.eastTriangle,
  view: ShapeType.circle,
}

export function stripShapeFromId (id: string) {
  return id.replace(/^[^-]*-/, '')
}
