import { css, cx } from "@emotion/css"
import './main.css'

export const panel = cx('card-panel', css`
  width: 100%;
  max-width: 800px;
  
  @media (max-width: 600px) {
    padding: 10px 0px;
  }
`)

export const overlayHoverClass = css`
  .to-hide {
    opacity: 1;
  }
`

export const ioClass = css`
  pointer-events: stroke;
  white-space: nowrap;
  background-color: #e7e7e7;
  padding: 8px;
  border-radius: 6px;
  cursor: grab;
  position: relative;
`

export const ioInput = css`

`

export const inputClass = `input-drop ${ioClass} ${ioInput}`

export const uiOutput = css`

`

export const outputClass = `${ioClass} ${uiOutput}`

export const toolItem = cx('btn blue', css`
  margin: 10px;
`)

export const overlayToolsClass = css`
  position: fixed;
  margin-top: -60px;
  margin-left: -20px;
  display: flex;
  flex-direction: row;
`
