export enum Route {
  home = '',
  edit = 'edit',
}

export const RouteRegExp = {
  '': /^$/,
  home: /^$/,
  edit: /^edit$/,
}
