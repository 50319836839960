import { v4 as uuid } from 'uuid'
import { newCalculation } from '../../../domain/calculation/command'
import { newDatabase } from '../../../domain/database/command'
import { newDatagrid } from '../../../domain/datagrid/command'
import { newFlow } from '../../../domain/flow/command'
import { newView } from '../../../domain/view/command'
import { IndexedDBEntity } from '../../../persistence/indexedDB'

const unit = 200

export function createAmmortizationExampleFlow () {
  const tutorialId = uuid()
  newDatabase({ id: `db-p-${tutorialId}`, x: 1 * unit, y: 1 * unit })
  newDatabase({ id: `db-i-${tutorialId}`, x: 1 * unit, y: 2 * unit })
  newDatabase({ id: `db-t-${tutorialId}`, x: 1 * unit, y: 3 * unit })
  newDatagrid({ id: `db-p-${tutorialId}`, columnStyle: [{ name: 'id', hidden: true }, { name: 'Principal' }], dataset: [[uuid(), '100000']] })
  newDatagrid({ id: `db-i-${tutorialId}`, columnStyle: [{ name: 'id', hidden: true }, { name: 'Interest rate' }], dataset: [[uuid(), '.03'],[uuid(), '.04'],[uuid(), '.05'],[uuid(), '.06'],[uuid(), '.07']] })
  newDatagrid({ id: `db-t-${tutorialId}`, columnStyle: [{ name: 'id', hidden: true }, { name: 'Term' }], dataset: [[uuid(), '15'], [uuid(), '30']] })
  newCalculation({ id: `calc-${tutorialId}`, x: 3 * unit, y: 2 * unit, equation: 'Principal*Annual interest rate/12/(1-(1+Annual interest rate/12)^-(Loan term years*12))', output: 'Monthly payment' })
  newView({ id: `view-${tutorialId}`, x: 5 * unit, y: 2 * unit })
  setTimeout(() => {
    newFlow({
      destinationEntity: IndexedDBEntity.calculation,
      destinationId: `calc-${tutorialId}`,
      destinationIndex: 0,
      destinationTitle: 'Principal',
      sourceEntity: IndexedDBEntity.database,
      sourceId: `db-p-${tutorialId}`,
      sourceIndex: 1,
      sourceTitle: 'Principal'
    })
    newFlow({
      destinationEntity: IndexedDBEntity.calculation,
      destinationId: `calc-${tutorialId}`,
      destinationIndex: 1,
      destinationTitle: 'Principal',
      sourceEntity: IndexedDBEntity.database,
      sourceId: `db-i-${tutorialId}`,
      sourceIndex: 1,
      sourceTitle: 'Principal'
    })
    newFlow({
      destinationEntity: IndexedDBEntity.calculation,
      destinationId: `calc-${tutorialId}`,
      destinationIndex: 2,
      destinationTitle: 'Principal',
      sourceEntity: IndexedDBEntity.database,
      sourceId: `db-t-${tutorialId}`,
      sourceIndex: 1,
      sourceTitle: 'Principal'
    })
    newFlow({
      destinationEntity: IndexedDBEntity.view,
      destinationId: `view-${tutorialId}`,
      destinationIndex: 0,
      destinationTitle: 'Input',
      sourceEntity: IndexedDBEntity.calculation,
      sourceId: `calc-${tutorialId}`,
      sourceIndex: 0,
      sourceTitle: 'Monthly payment'
    })
  }, 1000)
}
