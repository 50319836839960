import { css } from "@emotion/css"
import {
  distinctUntilChanged,
  filter,
  from,
  map,
  merge,
  Observable,
  of,
  share,
  startWith,
  switchMap,
  takeUntil,
  withLatestFrom
} from "rxjs"
import { classSync, withAnimationFrame } from "@taterer/rx-jsx"
import { Tool } from "../../../domain/toolSettings"
import { setActiveToolPanel } from "../../../domain/toolSettings/command"
import { activeToolPanel$ } from "../../../domain/toolSettings/event"
import { removeOverlayDetail } from "../../../domain/displayOverlayDetail/command"
import { getAggregation } from "../../../domain/aggregation/query"
import { newDatagrid } from "../../../domain/datagrid/command"
import { datagridEntity$ } from "../../../domain/datagrid/event"
import { makeItFlow } from "../../../domain/flow"
import { flowEntity$ } from "../../../domain/flow/event"
import { getAllFlowsArray } from "../../../domain/flow/query"
import { withIndexedDb } from "../../../persistence/indexedDB"
import { getDatagrid } from "../../../domain/datagrid/query"
import { removeAggregation } from "../../../domain/aggregation/command"
import { overlayToolsClass, toolItem } from "../../styles"

const detailClass = css`
  background-color: #add8e6ee;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
`

const activeDetailClass = css`
  background-color: #eafaff !important;
`

export default function AggregationDetail ({
  destruction$,
  id,
}: {
  destruction$: Observable<any>,
  id: string,
}) {

  const aggregation$ = getAggregation(id).pipe(share())

  const datagridColumns$ = getDatagrid(id)
  .pipe(
    map(datagrid => datagrid.columnStyle.map((columStyle, index) => ({ name: columStyle.name, hidden: columStyle.hidden || false, index })))
  )

  const container$ = of(
    <div class={`${activeDetailClass} ${css`
      background-color: white;
      caret-color: auto;
      display: flex;
      flex-direction: column;
      white-space: nowrap;
    `}`}>
      <p>
        Add new branch
      </p>
      <div
        class={css`
          display: flex;
          flex-direction: row;
        `}
      >
      </div>
    </div>
  )

  activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    withLatestFrom(container$),
    distinctUntilChanged(),
    takeUntil(destruction$)
  )
  .subscribe(([isActive, container]) => {
    classSync(container, activeDetailClass, isActive)
  })

  const tool$ = activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    startWith(true),
    distinctUntilChanged(),
    map(active => {
      if (!active) return <div />
      return (
        <div class={overlayToolsClass}>
          <div class={toolItem} onclick={() => {
            removeAggregation({ id })
            setActiveToolPanel(undefined)
          }}>
            <i class="material-icons dp48">delete</i>
          </div>
        </div>
      )
    }),
    takeUntil(destruction$)
  )

  // get all flows, make it flow, save new datagrid (stripped of data)
  merge(flowEntity$, datagridEntity$.pipe(filter(i => i.id !== id)))
  .pipe(
    startWith(1),
    switchMap(() => from(getAllFlowsArray())),
    withIndexedDb(),
    switchMap(([flows, db]) => makeItFlow(db, flows, id)),
    map(value => {
      if (typeof value === 'string') {
        newDatagrid({ id })
      } else {
        value.dataset = []
        newDatagrid(value)
      }
    }),
    takeUntil(destruction$)
  )
  .subscribe()

  return (
    <div class={detailClass}
      onmousedown={() => {
        setActiveToolPanel({ id, type: Tool.aggregation, collapse: () => removeOverlayDetail({ id }) })
      }}>
      <div class={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}>
        <div>
          <div single$={tool$} />
          <span>Aggregation</span>
        </div>
        <div style='cursor: pointer;' onclick={() => {
          setActiveToolPanel(undefined)
          removeOverlayDetail({ id })
        }}><i class="material-icons dp48">close</i></div>
      </div>
      <div single$={container$} />
    </div>
  )
}
