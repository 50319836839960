import { concatMap, filter, from, merge, Observable, switchMap } from "rxjs";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { Flow, flowEntity$ } from "./event";
import { FlowEvent } from "./command";

export function getAllFlows(): Observable<Flow> {
  return indexedDB$
  .pipe(
    concatMap(db => db.query(IndexedDBEntity.flow)),
    switchMap(values => from(values as Flow[])),
  )
}

export function getFlow(id): Observable<Flow & {
  meta: {
      entity: IndexedDBEntity;
      eventType: FlowEvent;
  };
}> {
  return merge(
    indexedDB$.pipe(concatMap(db => db.get(IndexedDBEntity.flow, id))),
    flowEntity$.pipe(filter(i => i.id === id))
  ).pipe(filter(i => !!i))
}

export function getAllFlowsArray(): Observable<Flow[]> {
  return indexedDB$
  .pipe(
    concatMap(db => db.query(IndexedDBEntity.flow)),
  )
}
