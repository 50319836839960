import { v4 as uuid } from 'uuid'
import { Observable, shareReplay } from "rxjs";
import {
  SideEventHandlers,
  EntityEventHandlers,
  entityServiceFactory,
  subscriptionFactory
} from "@taterer/rx-entity";
import { indexedDB$, IndexedDBEntity } from "../../persistence/indexedDB";
import { newShape, removeShape } from '../shape/command';
import {
  NewAggregation,
  aggregationCommands$,
  AggregationEvent,
  MoveAggregation,
  RemoveAggregation,
  RenameAggregation
} from './command';
import { generateShapeId, toolToShapeMap } from '../shape';
import { removeOverlayDetail } from '../displayOverlayDetail/command';

export interface Aggregation {
  id: string
  deleted?: boolean
  created_at: number
  x: number
  y: number
  title?: string
}

const aggregationEventHandlers: EntityEventHandlers<Aggregation, AggregationEvent> = {
  [AggregationEvent.new]: (_, event: NewAggregation) => {
    return {
      id: event.id || uuid(),
      created_at: Date.now(),
      ...event,
    }
  },
  [AggregationEvent.remove]: (entity, event: RemoveAggregation) => {
    return {
      ...entity,
      deleted: true
    }
  },
  [AggregationEvent.rename]: (entity, event: RenameAggregation) => {
    return {
      ...entity,
      title: event.title
    }
  },
  [AggregationEvent.move]: (entity, event: MoveAggregation) => {
    return {
      ...entity,
      x: event.x || entity.x,
      y: event.y || entity.y,
    }
  },
}

const aggregationSideEventHandlers: SideEventHandlers<Aggregation, AggregationEvent> = {
  [AggregationEvent.new]: (_, event: NewAggregation, updatedEntity): Aggregation => {
    newShape({
      id: generateShapeId(updatedEntity.id, toolToShapeMap.aggregation),
      x: event.x,
      y: event.y,
      type: toolToShapeMap.aggregation,
    })
    return updatedEntity
  },
  [AggregationEvent.remove]: (_, event, updatedEntity) => {
    removeShape({ id: generateShapeId(event.id, toolToShapeMap.aggregation) })
    return updatedEntity
  },
}

export const aggregationEntity$ = aggregationCommands$
  .pipe(
    entityServiceFactory<Aggregation, any>(
      indexedDB$,
      IndexedDBEntity.aggregation,
      aggregationEventHandlers,
      aggregationSideEventHandlers
    ),
    shareReplay(1)
  )

export function subscribeToAggregationServices (destruction$: Observable<any>) {
  subscriptionFactory([aggregationEntity$], destruction$)
}
