import { merge } from "rxjs"
import { RowStyle, ColumnStyle } from "."
import { eventFactory, Meta } from "@taterer/rx-entity"
import { IndexedDBEntity } from "../../persistence/indexedDB"

export enum DatagridEvent {
  new = 'new',
  remove = 'remove',
  resize = 'resize',
  addRow = 'addRow',
  removeRow = 'removeRow',
  moveRow = 'moveRow',
  addColumn = 'addColumn',
  removeColumn = 'removeColumn',
  renameColumn = 'renameColumn',
  moveColumn = 'moveColumn',
  resizeColumn = 'resizeColumn',
  hideColumn = 'hideColumn',
  setCell = 'setCell',
}

export interface DatagridPartial {
  meta: Meta
  id: string
  dataset?: string[][]
  rowStyle?: RowStyle[]
  columnStyle?: ColumnStyle[]
  height?: number
  width?: number
}

export interface NewDatagrid {
  id: string
  dataset?: string[][]
  rowStyle?: RowStyle[]
  columnStyle?: ColumnStyle[]
  height?: number
  width?: number
}

export const [newDatagrid$, newDatagrid] = eventFactory<NewDatagrid>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.new })

export interface RemoveDatagrid {
  id: string
}

export const [removeDatagrid$, removeDatagrid] = eventFactory<RemoveDatagrid>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.remove })

export interface ResizeDatagrid {
  id: string
  height?: number
  width?: number
}

export const [resizeDatagrid$, resizeDatagrid] = eventFactory<ResizeDatagrid>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.resize })

export interface AddRow {
  id: string
  rowId?: string
  rowIndex?: number
}

export const [addRow$, addRow] = eventFactory<AddRow>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.addRow })

export interface RemoveRow {
  id: string
  rowId: string
}

export const [removeRow$, removeRow] = eventFactory<RemoveRow>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.removeRow })

export interface MoveRow {
  id: string
  rowId: string
  rowIndex: number
}

export const [moveRow$, moveRow] = eventFactory<MoveRow>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.moveRow })

export interface AddColumn {
  id: string
  index?: number
  title: string
}

export const [addColumn$, addColumn] = eventFactory<AddColumn>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.addColumn })

export interface RemoveColumn {
  id: string
  index: number
}

export const [removeColumn$, removeColumn] = eventFactory<RemoveColumn>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.removeColumn })

export interface RenameColumn {
  id: string
  index: number
  title: string
}

export const [renameColumn$, renameColumn] = eventFactory<RenameColumn>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.renameColumn })
export interface MoveColumn {
  id: string
  index: number
  targetIndex: number
}

export const [moveColumn$, moveColumn] = eventFactory<MoveColumn>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.moveColumn })

export interface ResizeColumn {
  id: string
  index: number
  width: number
}

export const [resizeColumn$, resizeColumn] = eventFactory<ResizeColumn>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.resizeColumn })

export interface HideColumn {
  id: string
  index: number
  hidden: boolean
}

export const [hideColumn$, hideColumn] = eventFactory<HideColumn>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.hideColumn })

export interface SetCell {
  id: string
  rowIndex: number
  index: number
  value: string
}

export const [setCell$, setCell] = eventFactory<SetCell>({ entity: IndexedDBEntity.datagrid, eventType: DatagridEvent.setCell })

export const datagridCommands$ = merge(
  newDatagrid$,
  removeDatagrid$,
  resizeDatagrid$,
  addRow$,
  removeRow$,
  moveRow$,
  addColumn$,
  removeColumn$,
  renameColumn$,
  moveColumn$,
  resizeColumn$,
  hideColumn$,
  setCell$,
)
