import { css } from "@emotion/css";
import { merge, of, share, Subject, takeUntil } from "rxjs";
import { newFlow } from "../../../domain/flow/command";
import { stripShapeFromId } from "../../../domain/shape";
import { IndexedDBEntity } from "../../../persistence/indexedDB";
import { inputClass } from "../../styles";
import { parseTransferData } from "./io";

export default function SplitInput ({ destruction$, shape }) {
  const id = stripShapeFromId(shape.id)

  const sink$ = merge(new Subject(), of(
    <span
      id={`input-0-${id}`}
      class={inputClass}
      ondragover={allowDrop}
      ondrop={event => {
        newFlow({
          ...parseTransferData(event),
          destinationEntity: IndexedDBEntity.split,
          destinationId: id,
          destinationIndex: 0,
          destinationTitle: "Sink",
        })
      }}>
      Input
    </span>
  ))
  .pipe(
    share(),
    takeUntil(destruction$)
  )
  
  function allowDrop (event) {
    event.preventDefault()
  }


  return (
    <div single$={sink$} />
  )
}
