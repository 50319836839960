import d3 = require("d3")
import { Shape } from "../event"

export function squarePath (path: d3.Selection<SVGPathElement, unknown, null, undefined>, shape: Shape) {
  return path
    .attr("id", `${shape.id}`)
    .attr("d", `M -50, -50 50,-50 50,50 -50,50`)
    .attr("stroke", shape.color || 'lightblue')
    .attr("fill", shape.color || 'lightblue')
    .attr("transform", `translate(${shape.x}, ${shape.y})`)
}
