import { EntityEventHandlers, entityServiceFactory } from '@taterer/rx-entity'
import { memoryDB$, MemoryEntity } from '../../persistence/memoryDB'
import { NewShape, MoveShape, RemoveShape, shapeCommands$, ShapeEvent, ShapeType } from './command'

export interface Shape {
  id: string
  deleted?: boolean
  x: number
  y: number
  type: ShapeType
  color?: string
  size?: number
}

export const shapeEventHandlers: EntityEventHandlers<Shape, ShapeEvent> = {
  [ShapeEvent.new]: (entity, event: NewShape) => {
    return {
      id: event.id,
      x: event.x,
      y: event.y,
      type: event.type,
      color: event.color,
    }
  },
  [ShapeEvent.move]: (entity, event: MoveShape) => {
    return {
      ...entity,
      x: event.x === undefined ? entity.x : event.x,
      y: event.y === undefined ? entity.y : event.y,
    }
  },
  [ShapeEvent.remove]: (entity, event: RemoveShape) => {
    return {
      ...entity,
      deleted: true
    }
  },
}

export const shapeEntity$ = shapeCommands$
  .pipe(
    entityServiceFactory(
      memoryDB$,
      MemoryEntity.shape,
      shapeEventHandlers,
    ),
  )
