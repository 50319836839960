import { css, cx } from "@emotion/css"
import {
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  Observable,
  of,
  share,
  skip,
  startWith,
  takeUntil,
  withLatestFrom
} from "rxjs"
import { classSync, fromValueElementKeyup$, withAnimationFrame } from "@taterer/rx-jsx"
import { formulateCalculation, removeCalculation } from "../../../domain/calculation/command"
import { getCalculationOnce } from "../../../domain/calculation/query"
import { getVariables } from "../../../domain/mathite"
import { mathmlTypeset, parseToMathML, useMathmlTypeset } from "../../../domain/mathite/mathml"
import { Tool } from "../../../domain/toolSettings"
import { setActiveToolPanel } from "../../../domain/toolSettings/command"
import { activeToolPanel$ } from "../../../domain/toolSettings/event"
import { removeOverlayDetail } from "../../../domain/displayOverlayDetail/command"
import { overlayToolsClass, toolItem } from "../../styles"

const detailClass = css`
  background-color: #add8e6ee;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
`

const activeDetailClass = css`
  background-color: #eafaff !important;
`

export default function CalculationDetail ({
  destruction$,
  id,
}: {
  destruction$: Observable<any>,
  id: string,
}) {
  const calculation$ = getCalculationOnce(id)
  .pipe(
    map(calculation => {
      const equation$ = of(
        <textarea id="codeText" class="materialize-textarea" style='height: 6em;'>{calculation.equation}</textarea>
      )

      const notes$ = of(
        <textarea id="notes" class="materialize-textarea">{calculation.notes || ''}</textarea>
      )

      const output$ = of(
        <input id='output' type='text' value={calculation.output || ''}/>
      )

      const inputVariablesElement$ = of(<div />)

      const equationKeyUp$ = fromValueElementKeyup$(equation$, calculation.equation)
      const notesTextKeyUp$ = fromValueElementKeyup$(notes$, calculation.notes)
      const outputTextKeyUp$ = fromValueElementKeyup$(output$, calculation.output)

      const codeAndVariables$ = equationKeyUp$
      .pipe(
        withAnimationFrame,
        map(codeText => [codeText, getVariables(codeText as string)]),
        share(),
      )

      const mathml$ = codeAndVariables$
      .pipe(
        map(([text, variables]) => parseToMathML(text as string, variables)),
        map(text => <div>
          \[{'\\color{black}'}{text}\]
        </div>),
        takeUntil(destruction$),
      )

      merge(equationKeyUp$, notesTextKeyUp$, outputTextKeyUp$)
      .pipe(
        debounceTime(1000),
        skip(1),
        withLatestFrom(equationKeyUp$, notesTextKeyUp$, outputTextKeyUp$),
        takeUntil(destruction$)
      )
      .subscribe(([_, equation, notes, output]) => {
        formulateCalculation({ id, equation, notes, output })
      })

      useMathmlTypeset(destruction$)

      mathml$
      .pipe(
        takeUntil(destruction$),
      )
      .subscribe(() => mathmlTypeset())

      return (
        <div
          class={`${activeDetailClass} ${css`
            background-color: white;
            caret-color: auto;
            padding: 5px;
          `}`}
          onmousedown={event => {
            setActiveToolPanel({ id, type: Tool.calculation, collapse: () => removeOverlayDetail({ id }) })
            event.stopPropagation()
          }}>
          <div class={css`
            overflow: auto;
            min-width: 700px;
          `}>
            <div>
              <div class={cx("input-field", css`
                width: 100%;
                margin-bottom: 0px;
              `)}
              single$={equation$}>
                <label class={calculation.equation ? 'active' : ''} for='codeText'>Equation</label>
              </div>
            </div>
            <div single$={inputVariablesElement$} />
            <div class={css`
              display: flex;
              width: 100%;
              justify-content: flex-start;
            `}>
              <div class='input-field' single$={output$}>
                <label class={calculation.output ? 'active' : ''} for='output'>Output</label>
              </div>
              <i class={cx("material-icons right", css`
                margin: 12px;
                margin-top: 28px;
              `)}>drag_handle</i>
              <div style='position: relative;'>
                <div class={css`
                  align-self: flex-start;
                  height: 200px;
                  width: 500px;
                  overflow: auto;
                  color: transparent;
                  font-size: 1.3em;
                  `}>
                  <div single$={mathml$} />
                </div>
              </div>
            </div>
            <div>
              <div class={cx("input-field", css`
                width: 100%;
              `)} single$={notes$}>
                <label class={calculation.notes ? 'active' : ''} for='notes'>Notes</label>
              </div>
            </div>
          </div>
        </div>
      )
    }),
    share(),
    takeUntil(destruction$)
  )

  activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    withLatestFrom(calculation$),
    distinctUntilChanged(),
    takeUntil(destruction$)
  )
  .subscribe(([isActive, calculation]) => {
    classSync(calculation, activeDetailClass, isActive)
  })

  const tool$ = activeToolPanel$
  .pipe(
    map(activeTool => !!(activeTool && activeTool.id === id)),
    startWith(true),
    distinctUntilChanged(),
    map(active => {
      if (!active) return <div />
      return (
        <div class={overlayToolsClass}>
          <div class={toolItem} onclick={() => {
            removeCalculation({ id })
            setActiveToolPanel(undefined)
          }}>
            <i class="material-icons dp48">delete</i>
          </div>
        </div>
      )
    }),
    takeUntil(destruction$)
  )

  return (
    <div class={detailClass}
      onmousedown={() => {
        setActiveToolPanel({ id, type: Tool.calculation, collapse: () => removeOverlayDetail({ id }) })
      }}>
      <div class={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      `}>
        <div>
          <div single$={tool$} />
          <span>Computation</span>
        </div>
        <div style='cursor: pointer;' onclick={() => {
          setActiveToolPanel(undefined)
          removeOverlayDetail({ id })
        }}><i class="material-icons dp48">close</i></div>
      </div>
      <div single$={calculation$} />
    </div>
  )
}
