import { css } from '@emotion/css'
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators'
import { Route } from '../../../domain/route'
import { firstPathChange$ } from '../Router/event'
import NavbarItem from './NavbarItem'

export default function Navbar ({ destruction$ }) {
  const newNav$ = firstPathChange$
  .pipe(
    map(path => path === Route.edit),
    distinctUntilChanged(),
    takeUntil(destruction$)
  )
  const nav$ = newNav$
  .pipe(
    map(isEditPath => {
      if (isEditPath) {
        return <nav class='blue' style={`width: 80px;`}>
        <ul id='nav-mobile'>
          <NavbarItem destruction$={newNav$} path={Route.home} title="Home" style={`width: 80px; z-index: 1;`} />
        </ul>
    </nav>
      } else {
        return (
          <div class={css`
            width: 100%;
          `}>
          <div class={css`
            position: fixed;
            z-index: 1;
            width: 100%;
          `}>
            <nav class='blue'>
                <ul id='nav-mobile'>
                  <NavbarItem destruction$={newNav$} path={Route.home} title="Home" />
                  <NavbarItem destruction$={newNav$} path={Route.edit} title="Flow" />
                </ul>
            </nav>
          </div>
          <div class={css`
            height: 64px;
          `}>
          </div>
        </div>
        )
      }
    }),
    takeUntil(destruction$)
  )

  return <div single$={nav$}
    class={css`
      width: 100%;
    `}
    />
}
