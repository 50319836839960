import { filter, map, takeUntil } from "rxjs";
import { aggregationEntity$ } from "../../../domain/aggregation/event";
import { calculationEntity$ } from "../../../domain/calculation/event";
import { databaseEntity$ } from "../../../domain/database/event";
import { stripShapeFromId } from "../../../domain/shape";
import { ShapeEvent, ShapeType } from "../../../domain/shape/command";
import { shapeEntity$ } from "../../../domain/shape/event";
import { splitEntity$ } from "../../../domain/split/event";
import { viewEntity$ } from "../../../domain/view/event";
import OverlayDetail from "../../components/OverlayDetail";

export function overlayDetail$ (destruction$) {
  return shapeEntity$
  .pipe(
    filter(shapeEntity => shapeEntity.meta.eventType === ShapeEvent.new),
    map(shapeEntity => {
      const entityId = stripShapeFromId(shapeEntity.id)
      const shape$ = (
        shapeEntity.type === ShapeType.square ? databaseEntity$ :
        shapeEntity.type === ShapeType.hexPara ? calculationEntity$ :
        shapeEntity.type === ShapeType.diamond ? splitEntity$ :
        shapeEntity.type === ShapeType.eastTriangle ? aggregationEntity$ :
        shapeEntity.type === ShapeType.circle ? viewEntity$ :
        null
      ).pipe(
        filter(entity => entityId === entity.id && entity.meta.eventType === 'move'),
        map(entity => ({ type: shapeEntity.type, x: entity.x, y: entity.y })),
      )
      return <OverlayDetail destruction$={destruction$} entityId={entityId} shape={shapeEntity} shape$={shape$} />
    }),
    takeUntil(destruction$)
  )
}
