import { css } from "@emotion/css";
import { fromEvent, map, takeUntil } from "rxjs";
import { setModal } from "../../../domain/modal/command";
import { modalChangedStartAndShare$, modalChanged$ } from "../../../domain/modal/event";

export default function Modal ({ destruction$ }) {
  const modalContent$ = modalChangedStartAndShare$
  .pipe(
    map(modal => {
      if (modal) {
        fromEvent(window, 'keydown')
        .pipe(
          takeUntil(modalChanged$)
        )
        .subscribe((event: any) => {
          if (event.key === 'Escape') {
            setModal(undefined)
          }
        })

        fromEvent(window, 'mousedown')
        .pipe(
          takeUntil(modalChanged$)
        )
        .subscribe((event: any) => {
          setModal(undefined)
        })

        return (
          <div class={css`
            position: fixed;
            top: 0px;
            left: 0px;
            height: 100vh;
            width: 100vw;
            overflow-y: auto;
            background-color: #eeeeee99;
            z-index: 1;
          `}>
            <div class={css`
              min-height: 100%;
              min-width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
            `}>
              {modal}
            </div>
          </div>
        )
      }
    }),
    takeUntil(destruction$)
  )

  return (
    <div single$={modalContent$} />
  )
}
