import { filter, take, takeUntil } from "rxjs"
import { removeFlow } from "../../../domain/flow/command"
import { LineEvent } from "../../../domain/line/command"
import { Line, lineEntity$ } from "../../../domain/line/event"
import { ShapeEvent } from "../../../domain/shape/command"
import { shapeEntity$ } from "../../../domain/shape/event"

declare const LeaderLine;

function drawLine (line: Line) {
  const sourceElement = document.getElementById(line.sourceElementId)
  const destinationElement = document.getElementById(line.destinationElementId)
  if (!sourceElement || !destinationElement) {
    return {
      position: () => {},
      remove: () => {}
    }
  }
  const leaderLine = new LeaderLine(
    document.getElementById(line.sourceElementId),
    document.getElementById(line.destinationElementId),
    {
      color: line.color,
      zIndex: -1
    }
  )
  return leaderLine
}

export function subscribeToLines (destruction$) {
  lineEntity$
  .pipe(
    filter(i => i.meta.eventType === LineEvent.new),
    takeUntil(destruction$)
  )
  .subscribe(line => {
    let leaderLine = drawLine(line)

    const destroyLeaderLine$ = lineEntity$
    .pipe(
      filter(i => i.meta.eventType === LineEvent.remove),
      filter(i => i.id === line.id),
    )

    lineEntity$
    .pipe(
      filter(i => i.meta.eventType === LineEvent.new && i.destinationElementId === line.destinationElementId),
      take(1),
      takeUntil(destroyLeaderLine$),
      takeUntil(destruction$)
    )
    .subscribe(() => removeFlow({ id: line.id }))

    shapeEntity$
    .pipe(
      filter(i => i.id === line.destinationShapeId || i.id === line.sourceShapeId),
      takeUntil(destroyLeaderLine$),
      takeUntil(destruction$)
    )
    .subscribe({
      next: shape => {
        if (shape.meta.eventType === ShapeEvent.remove) {
          removeFlow({ id: line.id })
        } else {
          try {
            leaderLine.position()
          } catch (err) {
            leaderLine.remove()
            leaderLine = drawLine(line)
          }
        }
      },
      complete: () => leaderLine.remove()
    })
  })
}
