import { merge } from "rxjs"
import { eventFactory } from "@taterer/rx-entity"
import { IndexedDBEntity } from "../../persistence/indexedDB"

export enum FlowEvent {
  new = 'new',
  remove = 'remove',
  sync = 'sync',
}

export interface FlowSource {
  sourceId: string,
  sourceEntity: IndexedDBEntity,
  sourceIndex: number,
  sourceTitle: string,
}

export interface NewFlow extends FlowSource {
  destinationId: string,
  destinationEntity: IndexedDBEntity,
  destinationIndex: number,
  destinationTitle: string,
}

export const [newFlow$, newFlow] = eventFactory<NewFlow>({ entity: IndexedDBEntity.flow, eventType: FlowEvent.new })

export interface RemoveFlow {
  id: string
}

export const [removeFlow$, removeFlow] = eventFactory<RemoveFlow>({ entity: IndexedDBEntity.flow, eventType: FlowEvent.remove })

export interface SyncFlow {
  sourceId: string,
  sourceEntity: IndexedDBEntity,
  sourceIndex: number,
  sourceTitle: string,
  destinationId: string,
  destinationEntity: IndexedDBEntity,
  destinationIndex: number,
  destinationTitle: string,
}

export const [syncFlow$, syncFlow] = eventFactory<SyncFlow>({ entity: IndexedDBEntity.flow, eventType: FlowEvent.sync })

export const flowCommands$ = merge(
  newFlow$,
  removeFlow$,
)
