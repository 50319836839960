import { css } from "@emotion/css"
import { withAnimationFrame } from "@taterer/rx-jsx"
import { filter, map, Observable, skip, take, takeUntil } from "rxjs"
import { newCalculation$ } from "../../../domain/calculation/command"
import { newDatabase$ } from "../../../domain/database/command"
import { newFlow$ } from "../../../domain/flow/command"
import { Tool } from "../../../domain/toolSettings"
import { setActiveToolPanel$ } from "../../../domain/toolSettings/command"
import { completeTutorialStep, newTutorial, restartTutorial, skipTutorial, TutorialEvent } from "../../../domain/tutorial/command"
import { tutorialEntity$ } from "../../../domain/tutorial/event"
import { getTutorial } from "../../../domain/tutorial/query"
import { newView$ } from "../../../domain/view/command"
import { panel } from "../../styles"
import { createAmmortizationExampleFlow } from "./ammortizationExampleFlow"

export enum TutorialPosition {
  above = 'above',
  below = 'below',
  left = 'left',
  right = 'right',
  top = 'top',
}

interface TutorialStep {
  title: string
  instruction: string
  position: TutorialPosition
  observable?: Observable<any>
  documentSelectorId?: string
}

export const steps: TutorialStep[] = [
  {
    title: 'Welcome',
    instruction: <div>
      The fastest way to begin is with an example flow.<br />
      <br />
      <div class={css`
        display: flex;
        justify-content: space-around;
      `}>
        <a class="waves-effect waves-light btn" onclick={() => {
          completeTutorialStep({ id: 'userid' })
        }}>Skip</a>
        <a class="waves-effect waves-light btn" onclick={() => {
          createAmmortizationExampleFlow()
          completeTutorialStep({ id: 'userid' })
        }}>Create example flow</a>
      </div>
    </div>,
    position: TutorialPosition.top,
  },
  {
    title: 'Quick Start',
    instruction: <div>
      This is an ammortization flow. We have a table of principal values, interest rates, and terms. We have a calculation, and finally a visualization.
      <div class={css`
        display: flex;
        justify-content: flex-end;
      `}>
        <a class="waves-effect waves-light btn" onclick={() => completeTutorialStep({ id: 'userid' })}>Understood</a>
      </div>
    </div>,
    position: TutorialPosition.top,
  },
  {
    title: 'Quick Start',
    instruction: <div>
      When you define the equation for a calculation, it will automatically extract the variables. Essentially, anything that starts with a letter is a variable until the next operator EG -+/*().
      <div class={css`
        display: flex;
        justify-content: flex-end;
      `}>
        <a class="waves-effect waves-light btn" onclick={() => completeTutorialStep({ id: 'userid' })}>Understood</a>
      </div>
    </div>,
    position: TutorialPosition.top,
  },
  {
    title: 'Quick Start',
    instruction: <div>
      With tables of data and a calculation, you can start making a flow. You can put all variables into one table, but in this example we want to run the calculation for every combination between tables.<br />
      <br />
      EG: for each interest rate, calculate the result for every term.
      <div class={css`
        display: flex;
        justify-content: flex-end;
      `}>
        <a class="waves-effect waves-light btn" onclick={() => completeTutorialStep({ id: 'userid' })}>Understood</a>
      </div>
    </div>,
    position: TutorialPosition.top,
  },
  {
    title: 'Quick Start',
    instruction: <div>
      Finally, to see the monthly payment for every combination click on the visualization (the circle).
      <div class={css`
        display: flex;
        justify-content: flex-end;
      `}>
        <a class="waves-effect waves-light btn" onclick={() => completeTutorialStep({ id: 'userid' })}>Understood</a>
      </div>
    </div>,
    position: TutorialPosition.top,
    observable: setActiveToolPanel$.pipe(filter(i => !!i && i.type === Tool.view)),
  },
  {
    title: 'Guide',
    instruction: <div>
      Data tables store information. Columns in the table are used to pass values to other components.<br />
      <br />
      Click on the "Data Table" tool, then drag or click somewhere on the canvas to create a data table.
    </div>,
    position: TutorialPosition.right,
    observable: newDatabase$,
    documentSelectorId: `control-panel-${Tool.database}`,
  },
  {
    title: 'Guide',
    instruction: <div>
      Computations allow you to evaluate functions given data table values.<br />
      <br />
      Click on the "Computation" tool, then drag or click somewhere on the canvas to create a computation.
    </div>,
    position: TutorialPosition.right,
    observable: newCalculation$,
    documentSelectorId: `control-panel-${Tool.calculation}`,
  },
  {
    title: 'Guide',
    instruction: <div>
      Visualizations allow you to review the results of computations.<br />
      <br />
      Click on the "Visualization" tool, then drag or click somewhere on the canvas to create a visualization.
    </div>,
    position: TutorialPosition.right,
    observable: newView$,
    documentSelectorId: `control-panel-${Tool.view}`,
  },
  {
    title: 'Guide',
    instruction: <div>
      In order to perform a computation, all variables must be supplied through flows.<br />
      <br />
      Create a flow by clicking and dragging outputs <br />
      (values to the right of shapes)<br />
      <br />
      onto inputs (values to the left of shapes).
    </div>,
    position: TutorialPosition.top,
    observable: newFlow$,
  },
  {
    title: 'Guide',
    instruction: <div>
      Once all inputs are satisfied for the computation, connect the output to a visualization.<br />
      <br />
      Note: the values in the visualization will be automatically recalculated when any data changes that feeds into it.<br />
      <br />
      Click on the visualization.
    </div>,
    position: TutorialPosition.top,
    observable: setActiveToolPanel$.pipe(filter(i => !!i && i.type === Tool.view)),
  },
  {
    title: 'Help',
    instruction: <div>
      Click on the "?" in the bottom right to restart this guide at anytime.
      <div class={css`
        display: flex;
        justify-content: flex-end;
      `}>
        <a class="waves-effect waves-light btn" onclick={() => completeTutorialStep({ id: 'userid' })}>Complete</a>
      </div>
    </div>,
    position: TutorialPosition.top,
  },
]

export default function Tutorial ({ destruction$ }) {
  const tutorial$ = getTutorial('userid')
  .pipe(
    withAnimationFrame,
    withAnimationFrame,
    map(tutorial => {
      if (!tutorial || !tutorial.active) {
        return <div />
      }
      const step = steps[tutorial.stepIndex]
      if (!step) {
        if (tutorial?.meta?.eventType === TutorialEvent.new || tutorial?.meta?.eventType === TutorialEvent.continue) {
          restartTutorial({ id: 'userid' })
        }
        return <div />
      }
      const targetElement = document.getElementById(step.documentSelectorId)
      const boundingRectangle = !step.documentSelectorId || !targetElement ? { y: 0, x: 0, width: 0, height: 80 } : targetElement.getBoundingClientRect()

      if (step.observable) {
        step.observable
        .pipe(
          take(1),
          takeUntil(tutorialEntity$.pipe(skip(1))),
          takeUntil(destruction$)
        )
        .subscribe(() => {
          completeTutorialStep({ id: 'userid' })
        })
      }

      return (
        <div style={`
          pointer-events: none;
          position: absolute;
          top: ${step.position === TutorialPosition.left || step.position === TutorialPosition.right ? boundingRectangle.y + boundingRectangle.height / 2 :
            step.position === TutorialPosition.above ? boundingRectangle.y :
            step.position === TutorialPosition.below ? boundingRectangle.y + boundingRectangle.height :
            0}px;
          left: ${step.position === TutorialPosition.above || step.position === TutorialPosition.below ? boundingRectangle.x + boundingRectangle.width / 2 :
            step.position === TutorialPosition.left ? boundingRectangle.x :
            step.position === TutorialPosition.right ? boundingRectangle.x + boundingRectangle.width :
            0}px;
          ${step.position === TutorialPosition.top ? 'right: 0px;' : ''}
        `}>
          <div style='position: relative;'>
            <div style={`
              display: flex;
              align-items: center;
              justify-content: center;
              ${step.position === TutorialPosition.left || step.position === TutorialPosition.right ? 'height: 100%;' : 'width: 100%; flex-direction: column;'}
              ${step.position === TutorialPosition.left ? 'right: 0px;' : ''}
              ${step.position === TutorialPosition.above ? 'bottom: 0px;' : ''}
              position: absolute;
              `}>
                {step.position === TutorialPosition.right ? <i class="material-icons medium" style='margin: -20px;'>chevron_left</i> : <div />}
                {step.position === TutorialPosition.below ? <i class="material-icons medium" style='margin: -20px; margin-bottom: -28px;'>expand_less</i> : <div />}
                <div class={panel} style='position: relative; width: 400px; pointer-events: stroke;'>
                  <div style='position: absolute; top: 20px; right: 20px;'>
                    <i class="material-icons dp48" style='cursor: pointer;' onclick={() => skipTutorial({ id: 'userid' })} tooltip='Restart'>close</i>
                  </div>
                  <div style='position: absolute; top: 20px; right: 50px;'>
                    <i class="material-icons dp48" style='cursor: pointer;' onclick={() => restartTutorial({ id: 'userid' })}>replay</i>
                  </div>
                  <h4 style='width: 200px;'>{step.title}</h4>
                  <p>{step.instruction}</p>
                </div>
                {step.position === TutorialPosition.left ? <i class="material-icons medium" style='margin: -20px;'>chevron_right</i> : <div />}
                {step.position === TutorialPosition.above ? <i class="material-icons medium" style='margin: -20px; margin-top: -36px;'>expand_more</i> : <div />}
            </div>
          </div>
        </div>
      )
    }),
    takeUntil(destruction$)
  )

  setTimeout(() => newTutorial({ id: 'userid' }))

  return <div single$={tutorial$} />
}
