import { merge } from "rxjs"
import { eventFactory } from "@taterer/rx-entity"
import { MemoryEntity } from "../../persistence/memoryDB"

export enum ShapeEvent {
  new = 'new',
  move = 'move',
  remove = 'remove',
}

export enum ShapeType {
  circle = "circle",
  square = "square",
  diamond = "diamond",
  hexPara = "hexPara",
  eastTriangle = "eastTriangle",
}

export interface NewShape {
  id: string
  type: ShapeType
  x?: number
  y?: number
  height?: number
  width?: number
  color?: string
}

export const [newShape$, newShape] = eventFactory<NewShape>({ entity: MemoryEntity.shape, eventType: ShapeEvent.new })

export interface MoveShape {
  id: string
  x: number
  y: number
}

export const [moveShape$, moveShape] = eventFactory<MoveShape>({ entity: MemoryEntity.shape, eventType: ShapeEvent.move })

export interface RemoveShape {
  id: string
}

export const [removeShape$, removeShape] = eventFactory<RemoveShape>({ entity: MemoryEntity.shape, eventType: ShapeEvent.remove })

export const shapeCommands$ = merge(
  newShape$,
  moveShape$,
  removeShape$,
)