import { merge } from "rxjs"
import { eventFactory } from "@taterer/rx-entity"
import { IndexedDBEntity } from "../../persistence/indexedDB"

export enum DatabaseEvent {
  new = 'new',
  remove = 'remove',
  rename = 'rename',
  move = 'move',
}

export interface NewDatabase {
  id?: string,
  title?: string,
  x: number,
  y: number,
}

export const [newDatabase$, newDatabase] = eventFactory<NewDatabase>({ entity: IndexedDBEntity.database, eventType: DatabaseEvent.new })

export interface RemoveDatabase {
  id: string
}

export const [removeDatabase$, removeDatabase] = eventFactory<RemoveDatabase>({ entity: IndexedDBEntity.database, eventType: DatabaseEvent.remove })

export interface RenameDatabase {
  id: string
  title: string
}

export const [renameDatabase$, renameDatabase] = eventFactory<RenameDatabase>({ entity: IndexedDBEntity.database, eventType: DatabaseEvent.rename })

export interface MoveDatabase {
  id: string
  x: number
  y: number
}

export const [moveDatabase$, moveDatabase] = eventFactory<MoveDatabase>({ entity: IndexedDBEntity.database, eventType: DatabaseEvent.move })

export const databaseCommands$ = merge(
  newDatabase$,
  removeDatabase$,
  renameDatabase$,
  moveDatabase$,
)
